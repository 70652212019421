import { rgba } from "polished";
import themeDefault from "./themeDefault";

const colorTextForDark = rgba("white", 0.96);

export default {
  id: "lightMain",
  ...themeDefault,
  borderRadius: "0.75rem",
  colorPrimary: "#14142C",
  colorWhite: "#ffffff",
  listBorder: "#E6E6E7",
  whiteSmoke: "#E6E6E7",
  whiteSmokeText: "#7B7B7C",
  pistaLight:"#A2EBC6",
  textSecondary:"#868687",
  success: "#09C07E",
  info: "#278FEB",
  danger: "#ED5033",
  skyBlue:"#A9D2F7",
  skyBlueText:"#144876",
  navyBlue:"#31326F",
  navyBlueText:"#EAEAF0",
  whiteSmokeBlue:"#EAEAF0",
  whiteSmokeBlueText:"#ADADC5",
  colorYellow:"#FFC93C",
  colorYellowText:"#665018",
  colorBackground: "#ffffff",
  tableBorder: "#D6D6D6",
  rowEven: "#EEEEEE",
  colorText: "#010426",
  colorNavbar: "#151939",
  colorNavbarLabel: rgba("white", 0.65),
  colorNavbarLink: colorTextForDark,
  colorLabel: "#000839",
  colorBorder: "#f0f0f9",
  colorPrimaryText: colorTextForDark,
  colorAccent: "#9DDFD3",
  colorAccentText: colorTextForDark,
  colorCardBackground: "#ffffff",
  colorDefaultBackground: "#ececf0",
  colorDefaultText: "#73707C",
  colorDisabledBackground: "#d5d5e3",
  colorDisabledText: "#bebed0",
  colorCode: "#a5a5a5",
  colorChartShading: "#696969",
  boxShadowLogo: "none",
  buttonFillColor: "#ffffff",
  whiteSmokeLight: "#F9F9FA",
  whiteSmokeBorder: "#E4E5EA",
  sidebarIcon: "#717591",
  sidebarIconActive: "#8D5B0F",
  sideBarColor: "#EEEEEE",
  colorTextSecondary: "#4E5375",
  statiGrowth: "#00ECA4",
  statiShrink: "#EC0000",
  placeholderColor: "#a9a9a9",
};
