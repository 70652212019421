import React, { useMemo } from "react";
import { useForm } from "react-hook-form";
import english from "../../assets/images/english.jpg";
import arabic from "../../assets/images/arabic.jpg";
import urdu from "../../assets/images/urdu.jpg";
import hindi from "../../assets/images/hindi.jpg";
import spanish from "../../assets/images/spanish.jpg";
import malayalam from "../../assets/images/malayalam.jpg";
export default function LanguageForm({
  action,
  data,
  setLanguage,
  isLoading,
  language,
}) {
  // console.log(window.screen.width);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    shouldUseNativeValidation: true,
  });
  const swithToInfo = (data, e) => {
    e.preventDefault();
    setLanguage(data.language);
    action();
  };
  let languageImages = {
    English: english,
    Arabic: arabic,
    Urdu: urdu,
    Spanish: spanish,
    Hindi: hindi,
    Malayalam: malayalam,
    Tamil: malayalam,
  };
  const requiredLabel = useMemo(() => {
    if (errors.language) {
      return true;
    } else {
      return false;
    }
  }, [errors]);
  return (
    <form onSubmit={handleSubmit(swithToInfo)}>
      <div className="row padding-class language-select">
        <div className="col-lg-12 mb-2">
          <h3 className="form-head">Choose your language</h3>
        </div>
        {data.map((itm, i) => {
          return (
            <div className="col-lg-4" key={i}>
              <div className="form-group">
                <label
                  className={`container_radio version_2 ${
                    requiredLabel ? "validation-language-err" : ""
                  }`}
                >
                  {itm.get_language_name}
                  {/* <span htmlFor="question_1[]" className="error">Required</span> */}
                  <input
                    type="radio"
                    {...register("language", {
                      required: true,
                    })}
                    value={itm.language_access}
                    className="required"
                    onChange={(e) => setLanguage(e.target.value)}
                    checked={itm.language_access == language}
                  />
                  <img
                    src={languageImages[itm.get_language_name]}
                    alt=""
                    className="img-fluid"
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
            </div>
          );
        })}

        <div className="col-lg-12 text-center">
          <button
            type="submit"
            className="submit-button-class"
            disabled={isLoading}
          >
            {isLoading ? (
              <i className="fa fa-spinner" aria-hidden="true"></i>
            ) : (
              "Start Survey"
            )}
          </button>
        </div>
      </div>
    </form>
  );
}
