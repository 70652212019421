import React, { useState } from "react";
import styled, { css } from "styled-components";
import { rgba } from "polished";
const Toast = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [type, setType] = useState();
  const [message, setMessage] = useState();
  const [time, setTime] = useState(3000);
  const toast = (tmessage, ttype,ttime) => {
    setType(ttype);
    setMessage(tmessage);
    setTime(ttime);
    setIsOpen(true);
  };
  const color = (value) => {
    switch (value) {
      case "success":
        return { color: "#042917", bg: "#16CD71" };
      case "danger":
        return { color: "#FDEDEA", bg: "#ED5033" };
      case "warning":
        return { color: "#33280C", bg: "#FFC93C" };
      case "info":
        return { color: "#E9F4FD", bg: "#278FEB" };
      default:
        return { color: "#EAEAF0", bg: "#31326F" };
    }
  };
  const ToastAlert = () => {
    setTimeout(() => {
      setIsOpen(false);
      setTime(3000);
    }, time||3000);
    return isOpen ? (
      <Wrapper>
        <AlertContent color={color(type)}>
          <AlertMessage>{message}</AlertMessage>
          <i className="icon-close" onClick={() => setIsOpen(false)}></i>
        </AlertContent>
      </Wrapper>
    ) : (
      <></>
    );
  };
  return {
    ToastAlert: ToastAlert,
    toast: toast,
  };
};
export default Toast;
const visible = css`
  visibility: visible;
  opacity: 1;
`;
const Wrapper = styled.div`
  position: fixed;
  top: 0;
  z-index: ${(p) => p.zIndex || 200};
  width: 100%;
  user-select: none;
  transition: all 0.2s ease-in-out;
`;

const AlertContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  background-color: ${(p) => p.color.bg};
  color: ${(p) => p.color.color};
  margin: auto;
  padding: 15px 15px;
  width: 100%;
  i{
    color: ${(p) => p.color.color};
    opacity: 0.5;
  }
`;
const AlertMessage = styled.p`
  font-size: 16px;
  font-weight: 500;
  margin: 0;
  width: 80%;
`;
// const {ToastAlert,toast} = Toast();
// <ToastAlert />
// toast("Something went wrong ","danger",3000);