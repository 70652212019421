import React from "react";
const Radio = React.forwardRef(({ label, ...rest }, ref) => (
  <div className="form-group">
    <label className="container_radio version_2">
      {label}
      <input type="radio" className="required" {...rest} ref={ref} />
      <span className="checkmark"></span>
    </label>
  </div>
));
export default Radio;
