import React, { useState } from "react";
import { useForm } from "react-hook-form";
// import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  Textarea,
  SelectBox,
  TextBox,
  Login,
  DateInput,
  ConfirmAlert,
  TimeInput,
} from "../";
export default function SurveyInfoForm({ action, data, language, isLoading }) {
  const [isConfirm, setIsConfirm] = useState(false);
  //   const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  //   const schema = yup
  //     .object({
  //       name: yup.string().required("Enter your name"),
  //       email: yup
  //         .string()
  //         .email()
  //         .required(),
  //       mobile: yup.string().matches(phoneRegExp, "Enter valid mobile number"),
  //     })
  //     .required();

  const dynamicValidation = () => {
    return (
      data !== null &&
      data.initial_obj.map((itm) => {
        if (itm.manadatory_type) {
          if (itm.Email_type) {
            return {
              [`input-${itm.pk}`]: yup
                .string()
                .email()
                .required("Enter your Email"),
            };
          } else {
            return {
              [`input-${itm.pk}`]: yup.string().required("Enter your name"),
            };
          }
        }
      })
    );
  };
  // console.log(...dynamicValidation());
  // const schema = yup.object(...dynamicValidation());
  const { register, handleSubmit,resetField } = useForm({
    shouldUseNativeValidation: true,
    // resolver: yupResolver(schema),
  });
  const save = async (data, e) => {
    e.preventDefault();
    setIsConfirm(false);
    action(data);
  };
  const languageStyles = (lan) => {
    switch (lan) {
      case "1":
        return { formType: "ltr", inputlabel: "choice_en", short: "EN" };
      case "2":
        return { formType: "rtl", inputlabel: "choice_ar", short: "AR" };
      case "3":
        return { formType: "rtl", inputlabel: "choice_ur", short: "UR" };
      case "4":
        return { formType: "ltr", inputlabel: "choice_ta", short: "TA" };
      case "5":
        return { formType: "ltr", inputlabel: "choice_hi", short: "HI" };
      default:
        return { ormType: "ltr", inputlabel: "choice_en", short: "EN" };
    }
  };
  let maxMin = (val) => {
    if (val.min_max_status) {
      return {
        maxLength: {
          value: val.initial_max_len,
          message: `Maximum Length ${val.initial_max_len}`,
        },
        minLength: {
          value: val.initial_min_len,
          message: `Minimum Length ${val.initial_min_len}`,
        },
      };
    }
  };
  const clearField =(val,name)=>{
    val===""&&resetField(name)
  }
  const dynamicFields = (item) => {
    // console.log(item.min_max_status,"dynamicFields");
    // return <div className="col-lg-4">hai</div>;
    // maxLength:5,
    // minLength:2
    switch (item.field_type) {
      case "selection":
        return (
          <div className="col-lg-4" key={item.pk}>
            <label>
              {item[languageStyles(language).inputlabel]}{" "}
              {item.instructions !== null && item.instructions !== "" && (
                <small className="text-danger">({item.instructions})</small>
              )}
            </label>
            <SelectBox
              name={`input-${item.pk}`}
              items={item.initial_field_choice.map((itm) => {
                return { label: itm.choice_name, value: itm.pk };
              })}
              placeholder={item[languageStyles(language).inputlabel]}
              {...register(`inputOp-${item.pk}`, {
                required: item.manadatory_type ? "Field is Required" : false,
              })}
            />
          </div>
        );
      case "Text Input":
        return (
          <div className="col-lg-4" key={item.pk}>
           <label>
              {item[languageStyles(language).inputlabel]}{" "}
              {item.instructions !== null && item.instructions !== "" && (
                <small className="text-danger">({item.instructions})</small>
              )}
            </label>
            {item.Email_type ? (
              <TextBox
                placeholder={item[languageStyles(language).inputlabel]}
                name={`input-${item.pk}`}
                {...register(`input-${item.pk}`, {
                  required: item.manadatory_type ? "Field is Required" : false,
                  pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                })}
                type="email"
              />
            ) : (
              <TextBox
                placeholder={item[languageStyles(language).inputlabel]}
                name={`input-${item.pk}`}
                {...register(`input-${item.pk}`, {
                  required: item.manadatory_type ? "Field is Required" : false,
                  validate: (value, formValues) =>
                    item.manadatory_type ? value.trim() !== "" : true,
                  ...maxMin(item),
                })}
                type="text"
              />
            )}
          </div>
        );
      case "Date Input":
        return (
          <div className="col-lg-4" key={item.pk}>
           <label>
              {item[languageStyles(language).inputlabel]}{" "}
              {item.instructions !== null && item.instructions !== "" && (
                <small className="text-danger">({item.instructions})</small>
              )}
            </label>
            <DateInput
              name={`input-${item.pk}`}
              placeholder={item[languageStyles(language).inputlabel]}
              {...register(`input-${item.pk}`, {
                required: item.manadatory_type ? "Field is Required" : false,
              })}
              min='1000-01-01' max='9999-12-31'
              onBlur={(e)=>clearField(e.target.value,`input-${item.pk}`)}
            />
          </div>
        );
      case "Number Input":
        return (
          <div className="col-lg-4" key={item.pk}>
           <label>
              {item[languageStyles(language).inputlabel]}{" "}
              {item.instructions !== null && item.instructions !== "" && (
                <small className="text-danger">({item.instructions})</small>
              )}
            </label>
            <TextBox
              placeholder={item[languageStyles(language).inputlabel]}
              name={`input-${item.pk}`}
              {...register(`input-${item.pk}`, {
                required: item.manadatory_type ? "Field is Required" : false,
                ...maxMin(item),
              })}
              type="number"
            />
          </div>
        );
      case "Date Input":
        return (
          <div className="col-lg-4" key={item.pk}>
           <label>
              {item[languageStyles(language).inputlabel]}{" "}
              {item.instructions !== null && item.instructions !== "" && (
                <small className="text-danger">({item.instructions})</small>
              )}
            </label>
            <DateInput
              name={`input-${item.pk}`}
              placeholder={item[languageStyles(language).inputlabel]}
              {...register(`input-${item.pk}`, {
                required: item.manadatory_type ? "Field is Required" : false,
              })}
            />
          </div>
        );
      case "Time Input":
        return (
          <div className="col-lg-4" key={item.pk}>
           <label>
              {item[languageStyles(language).inputlabel]}{" "}
              {item.instructions !== null && item.instructions !== "" && (
                <small className="text-danger">({item.instructions})</small>
              )}
            </label>
            <TimeInput
              name={`input-${item.pk}`}
              placeholder={item[languageStyles(language).inputlabel]}
              {...register(`input-${item.pk}`, {
                required: item.manadatory_type ? "Field is Required" : false,
              })}
            />
          </div>
        );
    }
  };

  const confirmMessage = (data, e) => {
    e.preventDefault();
    setIsConfirm(true);
  };
  return data !== null && data.login_required ? (
    <Login action={save} isLoading={isLoading} />
  ) : (
    <>
      <form onSubmit={handleSubmit(confirmMessage)}>
        <div
          className="row padding-class"
          dir={languageStyles(language).formType}
        >
          <div className="col-lg-12">
            <div className="d-flex justify-content-between align-items-center mt-2">
              <h3 className="form-head" style={{ marginTop: "0" }}>
                Please fill in these below fields
              </h3>
              <h6 style={{ color: "#333" }}>
                Exam in : <span>{languageStyles(language).short}</span>
              </h6>
            </div>
          </div>
          <>
            {data !== null &&
              data.initial_obj.map((itm, i) => {
                return dynamicFields(itm);
              })}
          </>
          {/* <div className="col-lg-4">
          <TextBox
            placeholder="Name"
            {...register("name", {
              required: {
                value: true,
                message: "Please enter your name",
              },
            })}
          />
        </div>
        <div className="col-lg-4">
          <TextBox
            placeholder="Email Address"
            {...register("email", { required: "Please enter your email" })}
          />
        </div>
        <div className="col-lg-4">
          <TextBox
            placeholder="Mobile Number"
            {...register("mobile", { required: "Please enter your mobile" })}
          />
        </div>
        <div className="col-lg-4">
          <SelectBox
            items={["Male", "Female"]}
            placeholder="Gender"
            {...register("gender", { required: "Please select your gender" })}
          />
        </div>
        <div className="col-lg-8">
          <Textarea
            placeholder="Address"
            {...register("address", { required: "Please enter your address" })}
          />
        </div> */}
          <div className="col-lg-12 text-center">
            <button
              type="submit"
              className="submit-button-class"
              disabled={isLoading}
            >
              {isLoading ? (
                <i className="fa fa-spinner" aria-hidden="true"></i>
              ) : (
                "Submit"
              )}
            </button>
          </div>
        </div>
      </form>
      <ConfirmAlert
        isActive={isConfirm}
        message="Are you sure you want to confirm the initial fields submission ?"
        closeAction={() => setIsConfirm(false)}
        confirmAction={handleSubmit(save)}
        actionName="Yes"
        closeName="No"
      />
    </>
  );
}
