import { createSlice } from "@reduxjs/toolkit";
const INITIAL_STATE = {
  matrixList: [],
  matrixCheckList: [],
};
const matrixSlice = createSlice({
  name: "survey",
  initialState: INITIAL_STATE,
  reducers: {
    addToMatrixList: (state, action) => {
      // console.log(action.payload);
      const itemExist = state.matrixList.find(
        (itm) =>
          itm.qid === action.payload.qid &&
          itm.sId === action.payload.sId &&
          itm.sqmId === action.payload.sqmId
      );
      // console.log(itemExist, "itemExist addToMatrixList",action.payload );
      if (itemExist === undefined) {
        state.matrixList.push({ ...action.payload });
      } else if (!itemExist) {
        state.matrixList.push({ ...action.payload });
      }
    },
    changeMatrixRadioAnswer: (state, action) => {
      // console.log(action.payload);
      let qid = action.payload.qid;
      let rid = action.payload.rowId;
      let ans = action.payload.ans;
      state.matrixList.forEach((itm) => {
        if (itm.qid === qid) {
          itm.data.forEach((row) => {
            if (row.rowId === rid) {
              row.ans = ans;
              row.error = false;
            }
          });
          //   itm.answer = action.payload.answer;
        }
      });
    },
    changeMatrixRadioError: (state, action) => {
      let qid = action.payload.qid;
      let rid = action.payload.rid;
      state.matrixList.forEach((itm) => {
        if (itm.qid === qid) {
          itm.data.forEach((row) => {
            if (rid.includes(row.rowId)) {
              row.error = true;
            }
          });
        }
      });
    },
    addToMatrixCheckList: (state, action) => {
      const itemExist = state.matrixCheckList.find(
        (itm) =>
          itm.qid === action.payload.qid &&
          itm.sId === action.payload.sId &&
          itm.sqmId === action.payload.sqmId
      );
      // console.log(itemExist, "itemExist addToMatrixCheckList",action.payload);
      if (itemExist === undefined) {
        state.matrixCheckList.push({ ...action.payload });
      } else if (!itemExist) {
        state.matrixCheckList.push({ ...action.payload });
      }
    },
    changeMatrixCheckAnswer: (state, action) => {
      let qid = action.payload.qid;
      let rid = action.payload.rowId;
      let ans = action.payload.ans;
      state.matrixCheckList.forEach((itm) => {
        if (itm.qid === qid) {
          itm.data.forEach((row) => {
            if (row.rowId === rid) {
              if (row.ans === null) {
                row.ans = [ans];
                row.error = false;
              } else {
                if (row.ans.includes(ans)) {
                  let filterAnswer = row.ans.filter((itms) => itms != ans);
                  row.ans = filterAnswer;
                  row.error = filterAnswer.length > 0 && false;
                } else {
                  row.ans = [...row.ans, ans];
                  row.error = false;
                }
              }
            }
          });
          //   itm.answer = action.payload.answer;
        }
      });
    },
    changeMatrixCheckError: (state, action) => {
      let qid = action.payload.qid;
      let rid = action.payload.rid;
      state.matrixCheckList.forEach((itm) => {
        if (itm.qid === qid) {
          itm.data.forEach((row) => {
            if (rid.includes(row.rowId)) {
              row.error = true;
            }
          });
        }
      });
    },
    removeAllMatrix: (state, action) => {
      state.matrixList = [];
      state.matrixCheckList = [];
    },
  },
});
export const {
  changeMatrixRadioAnswer,
  addToMatrixList,
  addToMatrixCheckList,
  changeMatrixCheckAnswer,
  removeAllMatrix,
  changeMatrixRadioError,
  changeMatrixCheckError,
} = matrixSlice.actions;
export default matrixSlice.reducer;
