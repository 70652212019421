import React, { useState, useImperativeHandle, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import {
  changeMatrixRadioError,
  changeMatrixCheckError,
  removeAllMatrix,
} from "../../redux/matrix";
import { changeAnswer, removeAllSurvey } from "../../redux/survey";
import {
  CheckboxWidget,
  TextWidget,
  RadioWidget,
  // CircularProgress,
  MatrixCheckWidget,
  MatrixWidget,
  DateTimeWidget,
  DateWidget,
  NumberWidget,
  TextareaWidget,
} from "../";
import { Link } from "react-router-dom";
import { BACKTO_DASHBOARD_URL } from "../../utils/constUrl";
export default function SectionForm({
  results,
  seconds,
  minutes,
  hours,
  questionTimer,
  data,
  reference,
  testMode,
}) {
  const dispatch = useDispatch();
  const { matrixList, matrixCheckList } = useSelector((state) => state.matrix);
  const { surveyList } = useSelector((state) => state.survey);
  const [matrixRadio, setMatrixRadio] = useState([]);
  const [matrixCheck, setMatrixCheck] = useState([]);
  const [current, setCurrent] = useState(0);
  const [maxSection, setMaxSection] = useState(0);
  const [attendSection, setAttendSection] = useState(0);
  const formRef = useRef();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
  } = useForm({
    shouldUseNativeValidation: false,
  });
  useEffect(() => {
    setMaxSection(data !== null ? data.pool.length : 0);
    setAttendSection(data !== null ? data.attend_question_list.length : 0);
  }, []);
  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name !== undefined) {
        let nameSplit = name.split("-");
        // console.log(name, "===", nameSplit[1], nameSplit[2], nameSplit[3]);
        inputAnswer(value[name], nameSplit[1], nameSplit[2], nameSplit[3]);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);
  // const { errors, isSubmitting } = formState;
  useImperativeHandle(reference, () => ({
    submitForm() {
      handleSubmit(autoSave)();
    },
  }));
  const inputAnswer = (ans, sqmId, sectionId, pk) => {
    dispatch(
      changeAnswer({
        sqmId: sqmId,
        sectionId: sectionId,
        qid: pk,
        ans: ans,
        checkBox: false,
      })
    );
  };
  const autoSave = (formRes) => {
    // e.preventDefault();
    // console.log(formRes);
    if (matrixCheckValidation() && matrixRadioValidation()) {
      results(answerFinder(formRes), true, reset);
    } else {
      console.log("validation error");
    }
  };
  const save = async (formRes, e) => {
    e.preventDefault();
    // console.log(formRes);
    if (matrixCheckValidation() && matrixRadioValidation()) {
      if (questionTimer) {
        if (hours > 0 || minutes > 0 || seconds > 0) {
          results(answerFinder(formRes), maxSection === current + 1, reset);
          formRef.current.reset();
        } else {
          results(answerFinder(formRes), true, reset);
          formRef.current.reset();
        }
      } else {
        results(answerFinder(formRes), maxSection === current + 1, reset);
        formRef.current.reset();
      }
      await dispatch(removeAllMatrix());
      await dispatch(removeAllSurvey());
      await setCurrent(current + 1);
    } else {
      console.log("validation error");
    }
  };
  const answerFinder = (formData) => {
    let ans = Object.keys(formData).map((itm) => {
      let isExistanswer = surveyList.filter(
        (itmf) =>
          itmf.qid === Number(itm.split("-")[3]) &&
          itmf.sId === Number(itm.split("-")[2]) &&
          itmf.sqmId === Number(itm.split("-")[1])
      );
      if (isExistanswer.length > 0) {
        return {
          s_q_m_id: Number(itm.split("-")[1]),
          qid: Number(itm.split("-")[3]),
          sid: Number(itm.split("-")[2]),
          ans: Array.isArray(formData[itm])
            ? formData[itm].length > 0
              ? formData[itm]
              : []
            : formData[itm] === null
            ? []
            : [formData[itm]],
          matrix: "",
        };
      }
    });
    let mat = [...matrixCheckList, ...matrixList].map((itm) => {
      return {
        s_q_m_id: itm.sqmId,
        qid: itm.qid,
        sid: itm.sId,
        ans: "",
        matrix: itm.data.map((mItm) => {
          return {
            r_id: mItm.rowId,
            ans_id: Array.isArray(mItm.ans)
              ? mItm.ans.length > 0
                ? mItm.ans
                : []
              : mItm.ans === null
              ? []
              : [mItm.ans],
          };
        }),
      };
    });
    return [...ans.filter((itm) => itm !== null && itm !== undefined), ...mat];
  };
  const matrixCheckValidation = () => {
    if (matrixCheck.length > 0) {
      let valTrue = 0;
      matrixCheck.map((itm) => {
        matrixCheckList.map(async (mq) => {
          if (mq.qid == itm) {
            let nullAnswers = mq.data.filter(
              (ansArr) => ansArr.ans == null || ansArr.ans.length === 0
            );
            if (nullAnswers.length > 0) {
              valTrue = valTrue + 1;
              await dispatch(
                changeMatrixCheckError({
                  qid: mq.qid,
                  rid: nullAnswers.map((itms) => {
                    return itms.rowId;
                  }),
                })
              );
            }
          }
        });
      });
      return valTrue > 0 ? false : true;
    } else {
      return true;
    }
  };
  const matrixRadioValidation = () => {
    if (matrixRadio.length > 0) {
      let valTrue = 0;
      matrixRadio.map((itm) => {
        matrixList.map(async (mq) => {
          if (mq.qid == itm) {
            let nullAnswers = mq.data.filter((ansArr) => ansArr.ans == null);
            if (nullAnswers.length > 0) {
              valTrue = valTrue + 1;
              await dispatch(
                changeMatrixRadioError({
                  qid: mq.qid,
                  rid: nullAnswers.map((itms) => {
                    return itms.rowId;
                  }),
                })
              );
            }
            // console.log(nullAnswers);
          }
        });
      });
      return valTrue > 0 ? false : true;
    } else {
      return true;
    }
  };
  const languageStyles = () => {
    switch (data.language_id) {
      case "1":
        return {
          formType: "ltr",
          inputlabel: "choice_en",
          question: "question_en",
          choice: "choice_en",
        };
      case "2":
        return {
          formType: "rtl",
          inputlabel: "choice_ar",
          question: "question_ar",
          choice: "choice_ar",
        };
      case "3":
        return {
          formType: "rtl",
          inputlabel: "choice_ur",
          question: "question_ur",
          choice: "choice_ur",
        };
      case "4":
        return {
          formType: "ltr",
          inputlabel: "choice_ta",
          question: "question_ta",
          choice: "choice_ta",
        };
      case "5":
        return {
          formType: "ltr",
          inputlabel: "choice_hi",
          question: "question_hi",
          choice: "choice_hi",
        };
      default:
        return {
          formType: "ltr",
          inputlabel: "choice_en",
          question: "question_en",
          choice: "choice_en",
        };
    }
  };
  const lengthValidation = (question) => {
    if (question.validate_entry) {
      return {
        minLength: question.min_validate_entry,
        maxLength: question.max_validate_entry,
      };
    }
  };
  const dynamicWidget = (question, sectionId, sqmId, qKey) => {
    // console.log(question, sectionId);
    switch (question.Question_type) {
      case "Radio":
        return (
          <RadioWidget
            isHorizontal={data.choice_type == 1 ? true : false}
            key={qKey}
            sequence={data.Sequence_number ? qKey + 1 : ""}
            question={question}
            language={data.language_id}
            sqmId={sqmId}
            sectionId={sectionId}
            name={`input-${sqmId}-${sectionId}-${question.pk}`}
            {...register(`input-${sqmId}-${sectionId}-${question.pk}`, {
              required: {
                value: question.manadatory,
                message: "Please enter your input",
              },
            })}
            inputError={
              Object.keys(errors).length === 0
                ? null
                : errors[`input-${sqmId}-${sectionId}-${question.pk}`]
            }
          />
        );
      // case "Radio":
      //   return (
      //     <RadioWidget
      //       question={question}
      //       {...register(`input-${question.pk}`, {
      //         required: {
      //           value: question.manadatory,
      //           message: "Please enter your input",
      //         },
      //       })}
      //       inputError={
      //         Object.keys(errors).length === 0
      //           ? null
      //           : errors[`input-${question.pk}`]
      //       }
      //     />
      //   );
      case "Checkbox":
        return (
          <CheckboxWidget
            isHorizontal={data.choice_type == 1 ? true : false}
            key={qKey}
            sequence={data.Sequence_number ? qKey + 1 : ""}
            question={question}
            language={data.language_id}
            sqmId={sqmId}
            sectionId={sectionId}
            name={`input-${sqmId}-${sectionId}-${question.pk}`}
            {...register(`input-${sqmId}-${sectionId}-${question.pk}`, {
              required: {
                value: question.manadatory,
                message: "Please enter your input",
              },
            })}
            inputError={
              Object.keys(errors).length === 0
                ? null
                : errors[`input-${sqmId}-${sectionId}-${question.pk}`]
            }
          />
        );
      case "multiple_line":
        return (
          <TextareaWidget
            key={qKey}
            sequence={data.Sequence_number ? qKey + 1 : ""}
            language={data.language_id}
            question={question}
            sqmId={sqmId}
            sectionId={sectionId}
            name={`input-${sqmId}-${sectionId}-${question.pk}`}
            {...register(`input-${sqmId}-${sectionId}-${question.pk}`, {
              required: {
                value: question.manadatory,
                message: "Please enter your input",
              },
              validate: (value, formValues) =>
                question.manadatory ? value.trim() !== "" : true,
            })}
            inputError={
              Object.keys(errors).length === 0
                ? null
                : errors[`input-${sqmId}-${sectionId}-${question.pk}`]
            }
          />
        );
      case "text":
        //validate_entry min_validate_entry max_validate_entry
        // minLength: 6, maxLength: 12
        if (question.validate_entry) {
          return (
            <TextWidget
              key={qKey}
              sequence={data.Sequence_number ? qKey + 1 : ""}
              language={data.language_id}
              question={question}
              sqmId={sqmId}
              sectionId={sectionId}
              name={`input-${sqmId}-${sectionId}-${question.pk}`}
              {...lengthValidation(question)}
              {...register(`input-${sqmId}-${sectionId}-${question.pk}`, {
                required: {
                  value: question.manadatory,
                  message: "Please enter your input",
                },
                validate: (value, formValues) =>
                  question.manadatory ? value.trim() !== "" : true,
              })}
              inputError={
                Object.keys(errors).length === 0
                  ? null
                  : errors[`input-${sqmId}-${sectionId}-${question.pk}`]
              }
            />
          );
        } else {
          return (
            <TextWidget
              key={qKey}
              sequence={data.Sequence_number ? qKey + 1 : ""}
              language={data.language_id}
              question={question}
              sqmId={sqmId}
              sectionId={sectionId}
              name={`input-${sqmId}-${sectionId}-${question.pk}`}
              {...register(`input-${sqmId}-${sectionId}-${question.pk}`, {
                required: {
                  value: question.manadatory,
                  message: "Please enter your input",
                },
                validate: (value, formValues) =>
                  question.manadatory ? value.trim() !== "" : true,
              })}
              inputError={
                Object.keys(errors).length === 0
                  ? null
                  : errors[`input-${sqmId}-${sectionId}-${question.pk}`]
              }
            />
          );
        }
      case "num_text":
        return (
          <NumberWidget
            key={qKey}
            sequence={data.Sequence_number ? qKey + 1 : ""}
            language={data.language_id}
            question={question}
            sqmId={sqmId}
            sectionId={sectionId}
            name={`input-${sqmId}-${sectionId}-${question.pk}`}
            {...register(`input-${sqmId}-${sectionId}-${question.pk}`, {
              required: {
                value: question.manadatory,
                message: "Please enter your input",
              },
            })}
            inputError={
              Object.keys(errors).length === 0
                ? null
                : errors[`input-${sqmId}-${sectionId}-${question.pk}`]
            }
          />
        );
      case "Date_type":
        return (
          <DateWidget
            key={qKey}
            sequence={data.Sequence_number ? qKey + 1 : ""}
            language={data.language_id}
            question={question}
            sqmId={sqmId}
            sectionId={sectionId}
            name={`input-${sqmId}-${sectionId}-${question.pk}`}
            {...register(`input-${sqmId}-${sectionId}-${question.pk}`, {
              required: {
                value: question.manadatory,
                message: "Please enter your input",
              },
            })}
            inputError={
              Object.keys(errors).length === 0
                ? null
                : errors[`input-${sqmId}-${sectionId}-${question.pk}`]
            }
          />
        );
      case "Datetime_type":
        return (
          <DateTimeWidget
            key={qKey}
            sequence={data.Sequence_number ? qKey + 1 : ""}
            language={data.language_id}
            question={question}
            sqmId={sqmId}
            sectionId={sectionId}
            name={`input-${sqmId}-${sectionId}-${question.pk}`}
            {...register(`input-${sqmId}-${sectionId}-${question.pk}`, {
              required: {
                value: question.manadatory,
                message: "Please enter your input",
              },
            })}
            inputError={
              Object.keys(errors).length === 0
                ? null
                : errors[`input-${sqmId}-${sectionId}-${question.pk}`]
            }
          />
        );
      case "Matrix_type":
        if (question.matrix_type === "one_choice") {
          return (
            <MatrixWidget
              key={qKey}
              sequence={data.Sequence_number ? qKey + 1 : ""}
              language={data.language_id}
              question={question}
              setMatrixRadio={setMatrixRadio}
              sectionId={sectionId}
              sqmId={sqmId}
            />
          );
        } else {
          return (
            <MatrixCheckWidget
              key={qKey}
              sequence={data.Sequence_number ? qKey + 1 : ""}
              language={data.language_id}
              question={question}
              setMatrixCheck={setMatrixCheck}
              sectionId={sectionId}
              sqmId={sqmId}
            />
          );
        }
    }
  };
  // console.log(data,"---------------");
  return (
    <div className="container-fluid px-0">
      <div id="form_container">
        <div className="row no-gutters">
          <div className="col-lg-12">
            <div className="top-bg d-flex align-items-center justify-content-between flex-md-row flex-column">
              <div className="exam-heading d-flex align-items-center">
                {/* <i
                  className="fa-solid fa-angle-left backicn"
                  //   onClick={() => navigate(-1)}
                ></i> */}
                <div>
                  <h2>{data.exam_title}</h2>
                  {data.information_list.map((itm, i) => (
                    <h6>
                      {itm.title}: {itm.answer}
                    </h6>
                  ))}
                  {/* <h6>Total Questions: {data.total_questions_count}</h6> */}
                </div>
              </div>

              <div className="total-tym">
                {testMode && (
                  <Link to={BACKTO_DASHBOARD_URL}>
                    <button type="button" className="dashboard-class ml-2">
                      Back to Dashboard
                    </button>
                  </Link>
                )}
                {questionTimer && (
                  <h6 className="d-flex align-items-end">
                    Total Time Left:
                    <div id="countdown">
                      {hours > 0 ? hours : 0}:{minutes > 0 ? minutes : 0}:
                      {seconds > 0 ? seconds : 0}
                    </div>
                  </h6>
                )}
              </div>
            </div>

            {/* <CircularProgress time="10s" /> */}
            <div dir={languageStyles().formType}>
              <form onSubmit={handleSubmit(save)} ref={formRef}>
                <div className="m-5">
                  <div id="middle-wizard">
                    {data != null &&
                      data.pool[current] !== undefined &&
                      data.pool[current].section_title !== null && (
                        <div className="section-heading">
                          {data.pool[current] !== undefined && (
                            <h2>{data.pool[current].section_title}</h2>
                          )}
                        </div>
                      )}
                    {data != null &&
                      data.pool[current] !== undefined &&
                      data.pool[current].question_details.map((qItm, qKey) => {
                        return dynamicWidget(
                          qItm.question_details[0],
                          qItm.Section_id_id,
                          qItm.pk,
                          qKey
                        );
                      })}
                  </div>
                  <div id="bottom-wizard">
                    {current + 1 !== 1 && data.back_button_status && (
                      <button
                        type="button"
                        name="backward"
                        className="backward"
                        // onClick={prev}
                      >
                        Prev
                      </button>
                    )}
                    {data.pool[current] !== undefined &&
                      maxSection !== current + 1 && (
                        <button
                          type="submit"
                          name="forward"
                          className="forward mx-1"
                        >
                          Next
                        </button>
                      )}
                    {maxSection === current + 1 && (
                      <button
                        type="submit"
                        name="process"
                        className="submit mx-1 submit-cbtn"
                      >
                        Submit
                      </button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div id="top-wizard">
          <div
            id="progressbar"
            style={{
              padding: current + attendSection === 0 ? 0 : 4,
              backgroundColor: "#351A5C",
              width: `${(
                ((current + attendSection) / (maxSection + attendSection)) *
                100
              ).toFixed()}%`,
            }}
          ></div>
          {data.progress === "Percentage" && (
            <span id="location">
              {`${(
                ((current + attendSection) / (maxSection + attendSection)) *
                100
              ).toFixed()}%`}{" "}
              completed
            </span>
          )}
          {data.progress === "Number" && (
            <span id="location">
              {attendSection + current + 1} of {maxSection + attendSection} |{" "}
              {current + attendSection} completed
            </span>
          )}
        </div>
      </div>
    </div>
  );
}
