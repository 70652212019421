import React, { useEffect } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
// import { useStyledTheme } from "./hooks";
import { ThemeProvider, createGlobalStyle } from "styled-components";
import { themeLightMain, themeDark } from ".";

const Global = createGlobalStyle`
  body {
    background: ${(p) => p.theme.colorBackground};
    color: ${(p) => p.theme.colorText};
    font-family: 'Montserrat', sans-serif;
  }
`;
const Wrapper = ({
    children,
    setTheme,
    theme,
    localStorageTheme,
    preloaderClassName,
    onLoadBegin = () => null
  }) => {
    onLoadBegin();
  
    const themes = [
      themeLightMain,
      themeDark,
    ];
  
    useEffect(() => {
      if (localStorageTheme) {
        const savedTheme =
          themes.find((obj) => obj.id === localStorageTheme) || themeLightMain;
          setTheme(savedTheme );
      }else {
        if (!theme.id) {
          setTheme(themeLightMain);
        }
      }
    }, [localStorageTheme]);
  
    // useEffect(() => {
    //   // const removeIconLoading = () =>
    //   //   document.getElementById("preloader").classList.remove("icon-loading");
    //   // if (theme.id) {
    //   //   document.getElementById("preloader").classList.remove("theme-loading");
    //   // }
    //   // document
    //   //   .getElementById("icon-script")
    //   //   .addEventListener("load", removeIconLoading);
  
    //   setTimeout(() => {
    //     // removeIconLoading();
    //     // removeFontLoading();
    //     document.getElementById("preloader").classList.remove("theme-loading");
    //   }, 500);
  
    //   // return () => {
    //   //   document
    //   //     .getElementById("icon-script")
    //   //     .removeEventListener("load", removeIconLoading);
    //   // };
    // }, [theme.id]);
    return (
        <ThemeProvider theme={theme}>
          <HelmetProvider>
            <Global />
            <Helmet>
              <link
                href="https://fonts.googleapis.com/icon?family=Material+Icons"
                rel="stylesheet"
              />
              <link
                id="icon-script"
                rel="stylesheet"
                href="https://unicons.iconscout.com/release/v3.0.6/css/line.css"
              />
              <link
                id="font-script"
                rel="stylesheet"
                href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap"
              />
    
              <link
                rel="stylesheet"
                href="//cdn.jsdelivr.net/chartist.js/latest/chartist.min.css"
              />
            </Helmet>
            {children}
          </HelmetProvider>
        </ThemeProvider>
      );
    };
    
    export default Wrapper;
    