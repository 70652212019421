import React, { useEffect } from "react";
import { Radio } from "..";
import twopng from "../../assets/images/2.png";
import { useDispatch, useSelector } from "react-redux";
import { changeAnswer, addToSurveyList } from "../../redux/survey";
import { MEDIA_URL } from "../../utils/constUrl";
import "../../assets/css/feedback.css";
const RadioWidgetRatingSecond = React.forwardRef(
  (
    {
      inputError,
      question,
      language,
      sectionId,
      sqmId,
      sequence,
      isHorizontal,
      idx,
      ...rest
    },
    ref
  ) => {
    const dispatch = useDispatch();
    const { surveyList } = useSelector((state) => state.survey);
    useEffect(() => {
      dispatch(
        addToSurveyList({
          sqmId,
          sId: sectionId === null ? 0 : sectionId,
          qid: question.pk,
          ans: null,
          required: question.manadatory,
        })
      );
    }, []);
    const inputAnswer = (ans) => {
      dispatch(
        changeAnswer({
          sqmId: sqmId,
          sectionId: sectionId === null ? 0 : sectionId,
          qid: question.pk,
          ans: ans,
        })
      );
    };
    const languageStyles = () => {
      switch (language) {
        case "1":
          return {
            formType: "ltr",
            inputlabel: "choice_en",
            question: "question_en",
            choice: "choice_en",
          };
        case "2":
          return {
            formType: "rtl",
            inputlabel: "choice_ar",
            question: "question_ar",
            choice: "choice_ar",
          };
        case "3":
          return {
            formType: "rtl",
            inputlabel: "choice_ur",
            question: "question_ur",
            choice: "choice_ur",
          };
        case "4":
          return {
            formType: "ltr",
            inputlabel: "choice_ta",
            question: "question_ta",
            choice: "choice_ta",
          };
        case "5":
          return {
            formType: "ltr",
            inputlabel: "choice_hi",
            question: "question_hi",
            choice: "choice_hi",
          };
        default:
          return {
            formType: "ltr",
            inputlabel: "choice_en",
            question: "question_en",
            choice: "choice_en",
          };
      }
    };
    //d-md-none d-block
    //d-md-block d-none
    return (
      <div className="row feedback_option_container nested-check">
        <h3 className="main_question quest-option text-start pb-1 sm-question">
          <strong style={{ fontSize: ".8rem" }}>
            {sequence && sequence + ". "}
            {question[languageStyles().question]}
          </strong>
        </h3>
        <div
          className={`col-12 feedback_option display-6 d-flex pe-0 pt-2 pb-2 pl-0 pr-0 ml-0 mr-0 d-flex justify-content-center nested-check-item ${
            idx % 2 === 0 ? "even-bg" : "odd-bg"
          }`}
        >
          <div className="row col-12 feedback-qa-wrapper">
            <div className="col-lg-5 col-md-5 col-sm-12 feedback-question-container d-flex align-items-center lg-question">
              <h3 className="main_question quest-option text-start pt-1 pb-1">
                <strong style={{ fontSize: ".8rem" }}>
                  {sequence && sequence + ". "}
                  {question[languageStyles().question]}
                </strong>
              </h3>
            </div>
            <div className="feedback-answer-options-container col-lg-7 col-md-7 col-sm-12 feedback-answer-container mt-1 mb-1 ps-0  review_block">
              <ul className="feedback-answer-option d-flex align-items-center pt-0 mt-0 justify-content-between">
                {question.multiple_choice.map((itm, ii) => {
                  return (
                    <li className="mx-4 px-md-3 " key={ii}>
                      <div className="checkbox_radio_container">
                        <input
                          {...rest}
                          ref={ref}
                          value={itm.id}
                          type="radio"
                          className="required form-check-input rating-value"
                        />
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
        <style jsx="true">{`
          .sm-question {
            display: none;
          }
          .lg-question {
            display: none;
          }
          @media only screen and (max-width: 767px) {
            .sm-question {
              display: block !important;
            }
            .lg-question {
              display: none !important;
            }
          }
        `}</style>
      </div>
    );
  }
);
export default RadioWidgetRatingSecond;
