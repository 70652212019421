import React, { useEffect } from "react";
import { Radio } from "../";
import twopng from "../../assets/images/2.png";
import { useDispatch, useSelector } from "react-redux";
import { changeAnswer, addToSurveyList } from "../../redux/survey";
import { MEDIA_URL } from "../../utils/constUrl";
const RadioWidget = React.forwardRef(
  (
    {
      inputError,
      question,
      language,
      sectionId,
      sqmId,
      sequence,
      isHorizontal,
      ...rest
    },
    ref
  ) => {
    const dispatch = useDispatch();
    const { surveyList } = useSelector((state) => state.survey);
    useEffect(() => {
      dispatch(
        addToSurveyList({
          sqmId,
          sId: sectionId === null ? 0 : sectionId,
          qid: question.pk,
          ans: null,
          required: question.manadatory,
        })
      );
    }, []);
    const inputAnswer = (ans) => {
      dispatch(
        changeAnswer({
          sqmId: sqmId,
          sectionId: sectionId === null ? 0 : sectionId,
          qid: question.pk,
          ans: ans,
        })
      );
    };
    const languageStyles = () => {
      switch (language) {
        case "1":
          return {
            formType: "ltr",
            inputlabel: "choice_en",
            question: "question_en",
            choice: "choice_en",
          };
        case "2":
          return {
            formType: "rtl",
            inputlabel: "choice_ar",
            question: "question_ar",
            choice: "choice_ar",
          };
        case "3":
          return {
            formType: "rtl",
            inputlabel: "choice_ur",
            question: "question_ur",
            choice: "choice_ur",
          };
        case "4":
          return {
            formType: "ltr",
            inputlabel: "choice_ta",
            question: "question_ta",
            choice: "choice_ta",
          };
        case "5":
          return {
            formType: "ltr",
            inputlabel: "choice_hi",
            question: "question_hi",
            choice: "choice_hi",
          };
        default:
          return {
            formType: "ltr",
            inputlabel: "choice_en",
            question: "question_en",
            choice: "choice_en",
          };
      }
    };
    return (
      <div className="step">
        <label className="custom add_top_10"></label>
        <h3 className="main_question">
          <i className="arrow_right"></i>
          {sequence && sequence + ". "}
          {question[languageStyles().question]}
        </h3>
        {question.Description !== "" && <small>{question.Description}</small>}
        {question.comments !== "" && <small>{question.comments}</small>}
        {question.multiple_image.length > 0 && (
          <div className="d-flex justify-content-center">
            {question.multiple_image.map((imgItm, im) => {
              return (
                <div className="qimg-box" key={im}>
                  <img
                    src={MEDIA_URL + imgItm.file_data}
                    className="img-fluid img-center"
                  />
                </div>
              );
            })}
          </div>
        )}
        {/* <div className="d-flex justify-content-center">
                <div className="qimg-box">
                    <img src={twopng} className="img-fluid img-center" alt=""/>
                </div>
            </div> */}
        <div
          className={`row d-flex ${
            isHorizontal ? "flex-row" : "flex-column"
          } justify-content-center align-items-center`}
        >
          {question.multiple_choice.map((itm, i) => {
            return (
              <div className="col-lg-3" key={i}>
                {i == 0 && inputError !== null && inputError !== undefined && (
                  <span htmlFor="question_2" className="error">
                    Required
                  </span>
                )}
                <div className="form-group">
                  <label className="container_radio version_2">
                    {itm[[languageStyles().choice]]}
                    <input
                      type="radio"
                      className="523option_value_radio 523last_input_value required"
                      {...rest}
                      ref={ref}
                      value={itm.id}
                    />
                    <span className="checkmark"></span>

                    {itm.Imagefield !== "" && itm.Imagefield !== null && (
                      <div
                        style={{
                          backgroundImage: `url(${MEDIA_URL + itm.Imagefield})`,
                        }}
                        className="option-img-bg"
                      >
                        {/* <img
                        src={MEDIA_URL + itm.Imagefield}
                        alt=""
                        className="opt-img img-fluid"
                      /> */}
                      </div>
                    )}
                  </label>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
);
export default RadioWidget;
