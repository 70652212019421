import React from "react";

export default function ConfirmAlert({
  message,
  actionName,
  closeName,
  isActive,closeAction,confirmAction
}) {
  return (
    <div
      className={`popup1 popup--icon -question ${isActive && "popup--visible"}`}
    >
      <div className="popup__background"></div>
      <div className="popup__content">
        <p>{message}</p>
        <div className="text-center row">
          <div className="col pr-1">
            <button className="button button--warning" style={{minWidth:"97px"}} onClick={confirmAction}>{actionName}</button>
          </div>
          <div className="col pl-1">
            <button className="button button--cls" style={{minWidth:"97px"}} onClick={closeAction}>{closeName}</button>
          </div>
        </div>
      </div>
    </div>
  );
}
