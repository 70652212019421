import React from "react";

export default function AlertModal({
  message,
  closeName,
  isActive,
  closeAction,
}) {
  return (
    <div
      className={`popup1 popup--icon -question ${isActive && "popup--visible"}`}
    >
      <div className="popup__background"></div>
      <div className="popup__content">
        <p>{message}</p>
        <div className="text-center">
          <button className="button button--warning" onClick={closeAction}>
            {closeName}
          </button>
        </div>
      </div>
    </div>
  );
}
