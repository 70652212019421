import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addToMatrixList, changeMatrixRadioAnswer } from "../../redux/matrix";
import { MEDIA_URL } from "../../utils/constUrl";
import "../../assets/css/feedback.css";
import sm1 from "../../assets/images/smiley-1.png";
import sm2 from "../../assets/images/smiley-2.png";
import sm3 from "../../assets/images/smiley-3.png";
import sm4 from "../../assets/images/smiley-4.png";
const MatrixWidget = ({
  inputError,
  question,
  setMatrixRadio,
  language,
  sectionId,
  sqmId,
  sequence,
}) => {
  const dispatch = useDispatch();
  const { matrixList } = useSelector((state) => state.matrix);
  useEffect(() => {
    initalDataForStore();
  }, [question.pk]);
  //   console.log(matrixList, "matrixListmatrixListmatrixList");
  const initalDataForStore = async () => {
    question.manadatory && setMatrixRadio((prev) => [...prev, question.pk]);
    let matrixData = {
      qid: question.pk,
      sId: sectionId,
      sqmId: sqmId,
      data: dynamicMatrixData(),
    };
    // console.log(matrixData, "matrixData radio");s
    await dispatch(addToMatrixList(matrixData));
  };
  const dynamicMatrixData = () => {
    return question.matrix_row.map((qitm, i) => {
      return {
        rowId: qitm.id,
        ans: null,
        error: false,
        required: question.manadatory,
      };
    });
  };
  const inputAnswer = async (ans, rowId) => {
    await dispatch(
      changeMatrixRadioAnswer({ qid: question.pk, ans: ans, rowId })
    );
  };
  const languageStyles = () => {
    switch (language) {
      case "1":
        return {
          formType: "ltr",
          inputlabel: "choice_en",
          question: "question_en",
          choice: "choice_en",
        };
      case "2":
        return {
          formType: "rtl",
          inputlabel: "choice_ar",
          question: "question_ar",
          choice: "choice_ar",
        };
      case "3":
        return {
          formType: "rtl",
          inputlabel: "choice_ur",
          question: "question_ur",
          choice: "choice_ur",
        };
      case "4":
        return {
          formType: "ltr",
          inputlabel: "choice_ta",
          question: "question_ta",
          choice: "choice_ta",
        };
      case "5":
        return {
          formType: "ltr",
          inputlabel: "choice_hi",
          question: "question_hi",
          choice: "choice_hi",
        };
      default:
        return {
          formType: "ltr",
          inputlabel: "choice_en",
          question: "question_en",
          choice: "choice_en",
        };
    }
  };
  const errorInMatrix = (id) => {
    let arrayQuest = matrixList.filter(
      (itm) => itm.qid === question.pk && sectionId === itm.sId
    );
    if (arrayQuest.length > 0) {
      return arrayQuest[0].data.filter((itm) => itm.rowId == id)[0].error;
    }
  };
  const isChecked = (qId, rowId, itmId) => {
    let arrayQuest = matrixList.filter(
      (itm) => itm.qid === qId && sectionId === itm.sId && sqmId === itm.sqmId
    );
    if (arrayQuest.length > 0) {
      return (
        arrayQuest[0].data.filter((itm) => itm.rowId == rowId)[0].ans == itmId
      );
    } else {
      return false;
    }
  };
  return (
    <div className="step">
      <div className="col-12 p-0 m-0">
        <div className="d-block">
          <div className="d-flex col-12 align-items-center justify-content-left question-area">
            <div
              className="question text-primary mt-0"
              style={{ marginTop: "0!important" }}
            >
              {sequence && sequence + ". "}
              {question[languageStyles().question]}
            </div>
          </div>
        </div>
      </div>
      {/* <h3 className="main_question">
        <i className="arrow_right"></i>
        {sequence && sequence + ". "}
        {question[languageStyles().question]}
      </h3> */}
      {question.Description !== "" && <small>{question.Description}</small>}
      {question.comments !== "" && <small>{question.comments}</small>}
      {question.multiple_image.length > 0 && (
        <div className="d-flex justify-content-center">
          {question.multiple_image.map((imgItm, im) => {
            return (
              <div className="qimg-box" key={im}>
                <img
                  src={MEDIA_URL + imgItm.file_data}
                  className="img-fluid img-center"
                />
              </div>
            );
          })}
        </div>
      )}
      <div className="row d-flex justify-content-center">
        <div className="col-12 mt-2 mb-3 px-3">
          <div className="row flex-fill flex-grow-1 align-items-center d-flex answer-area justify-content-around">
            <div className="col-12">
              <div className="row display-6 d-flex  pe-0 py-3 emoji-container-wrapper">
                <div className="col-lg-5 col-md-5 col-sm-12 before_emoji-container">
                  <a className="circle"></a>
                </div>
                <div className="col-lg-7 col-md-7 col-sm-12 emoji-container d-flex justify-content-center pl-0">
                  <ul className="col-12 emoji_ul d-inline-flex flex-row justify-content-between pl-0 pr-0 ml-0 mr-0 px-md-3 px-sm-0">
                    <li className="mx-3 li-emoji">
                      <img width="64" height="64" src={sm1} alt="sm1" />
                    </li>
                    <li className="mx-3 li-emoji">
                      <img width="64" height="64" src={sm2} alt="sm2" />
                    </li>
                    <li className="mx-3 li-emoji">
                      <img width="64" height="64" src={sm3} alt="sm3" />
                    </li>
                    <li className="mx-3 li-emoji">
                      <img width="64" height="64" src={sm4} alt="sm4" />
                    </li>
                  </ul>
                </div>
              </div>

              {question.matrix_row.map((qitm, i) => (
                <div className="row feedback_option_container" key={i}>
                  <h3 className="main_question quest-option text-start pb-1 d-block d-md-none d-lg-none">
                    <strong style={{ fontSize: ".8rem" }}>
                      {qitm[languageStyles().choice]}
                    </strong>
                  </h3>
                  <div
                    className={`col-12 feedback_option display-6 d-flex pe-0 pt-2 pb-2 pl-0 pr-0 ml-0 mr-0 d-flex justify-content-center ${
                      i % 2 === 0 ? "even-bg" : "odd-bg"
                    }`}
                  >
                    <div className="row col-12 feedback-qa-wrapper">
                      <div className="col-lg-5 col-md-5 col-sm-12 feedback-question-container d-flex align-items-center d-none">
                        <h3 className="main_question quest-option text-start pt-1 pb-1">
                          <strong style={{ fontSize: ".8rem" }}>
                            {qitm[languageStyles().choice]}
                          </strong>
                        </h3>
                      </div>
                      <div className="feedback-answer-options-container col-lg-7 col-md-7 col-sm-12 feedback-answer-container mt-1 mb-1 ps-0  review_block">
                        <ul className="feedback-answer-option d-flex align-items-center pt-0 mt-0 justify-content-between">
                          {question.matrix_answer.map((itm, ii) => {
                            return (
                              <li
                                className="mx-4 li_radio_option px-md-3"
                                key={ii}
                              >
                                <div className="checkbox_radio_container">
                                  <input
                                    name={`question_${question.pk}_${qitm.id}`}
                                    value={itm.id}
                                    onChange={(e) =>
                                      inputAnswer(e.target.value, qitm.id)
                                    }
                                    defaultChecked={isChecked(
                                      question.pk,
                                      qitm.id,
                                      itm.id
                                    )}
                                    type="radio"
                                    className="required form-check-input rating-value radioButtonGroup radio-custom"
                                  />
                                </div>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
// );
export default MatrixWidget;
