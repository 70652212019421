import React, { lazy, Suspense, useState } from "react";
import { MyThemeProvider } from "./library";
import styled from "styled-components";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { disableReactDevTools } from "@fvilers/disable-react-devtools";
import { PreLoader } from "./components/index.js";
const Home = lazy(() => import("./pages/Home.js"));
const Survey = lazy(() => import("./pages/Survey"));
const SurveyTest = lazy(() => import("./pages/SurveyTest"));
const SurveyInfo = lazy(() => import("./pages/SurveyInfo"));
const ResultsScreen = lazy(() => import("./pages/ResultsScreen"));
const ResultAnswer = lazy(() => import("./pages/ResultAnswer"));
const TestHome = lazy(() => import("./pages/TestHome"));
const SurveyInfoTest = lazy(() => import("./pages/SurveyInfoTest"));
const TestLogin = lazy(() => import("./pages/Auth/TestLogin"));
const Base = lazy(() => import("./pages/Base"));
const ResultTest = lazy(() => import("./pages/ResultTest"));
const GeneralInfo = lazy(() => import("./pages/GeneralInfo"));
const UserExamList = lazy(() => import("./pages/UserExamList"));
const SurveyInfoUser = lazy(() => import("./pages/SurveyInfoUser"));
const GeneralInfoTest = lazy(() => import("./pages/GeneralInfoTest"));
const UserTestExamList = lazy(() => import("./pages/UserTestExamList"));
const SurveyInfoUserTest = lazy(() => import("./pages/SurveyInfoUserTest"));
function App() {
  const [theme, setTheme] = useState({});
  const handleThemeSet = (activeTheme) => {
    localStorage.setItem("veuz-theme", activeTheme.id);
    setTheme({
      ...activeTheme,
    });
  };
  React.useEffect(() => {
    disableReactDevTools();
    window !== undefined &&
      window.document.addEventListener("contextmenu", (e) => {
        e.preventDefault();
      });
  }, []);

  document.onkeydown = function (e) {
    // console.log(e.key)
    if (e.key === "F12") {
      return false;
    }
    if (e.ctrlKey && e.shiftKey && e.key === "I") {
      return false;
    }
    if (e.ctrlKey && e.shiftKey && e.key === "C") {
      return false;
    }
    if (e.ctrlKey && e.shiftKey && e.key === "J") {
      return false;
    }
    if (e.ctrlKey && e.key === "u") {
      return false;
    }
  };
  return (
    <MyThemeProvider
      theme={theme}
      localStorageTheme={localStorage.getItem("veuz-theme")}
      setTheme={handleThemeSet}
      preloaderClassName="theme-loading"
    >
      <StyledWrapper>
        <BrowserRouter>
          <Routes>
            {/* <Route path="/" index element={<GeneralInfo />} /> */}
            <Route
              path="/"
              index
              element={
                <Suspense fallback={<PreLoader isLoading />}>
                  <Home />
                </Suspense>
              }
            />
            <Route
              path="/exam"
              element={
                <Suspense fallback={<PreLoader isLoading />}>
                  <Home />
                </Suspense>
              }
            />
            <Route
              path="/user-exam/:id"
              element={
                <Suspense fallback={<PreLoader isLoading />}>
                  <UserExamList />
                </Suspense>
              }
            />
            <Route
              path="/base"
              index
              element={
                <Suspense fallback={<PreLoader isLoading />}>
                  <Base />
                </Suspense>
              }
            />
            <Route
              path="/survey-info/:sid"
              element={
                <Suspense fallback={<PreLoader isLoading />}>
                  <SurveyInfo />
                </Suspense>
              }
            />
            <Route
              path="/user-survey-info/:id/:sid"
              element={
                <Suspense fallback={<PreLoader isLoading />}>
                  <SurveyInfoUser />
                </Suspense>
              }
            />
            <Route
              path="/start-survey/:sid"
              element={
                <Suspense fallback={<PreLoader isLoading />}>
                  <Survey />
                </Suspense>
              }
            />
            <Route
              path="/result/:sid/:id"
              element={
                <Suspense fallback={<PreLoader isLoading />}>
                  <ResultsScreen />
                </Suspense>
              }
            />
            <Route
              path="/result/:type/:sid"
              element={
                <Suspense fallback={<PreLoader isLoading />}>
                  <ResultsScreen />
                </Suspense>
              }
            />
            {/* //test links*/}
            <Route
              path="/test-mode-exam"
              index
              element={
                <Suspense fallback={<PreLoader isLoading />}>
                  <TestHome />
                </Suspense>
              }
            />
            <Route
              path="/test-mode"
              index
              element={
                <Suspense fallback={<PreLoader isLoading />}>
                  <GeneralInfoTest />
                </Suspense>
              }
            />
            <Route
              path="/user-test-exam/:id"
              index
              element={
                <Suspense fallback={<PreLoader isLoading />}>
                  <UserTestExamList />
                </Suspense>
              }
            />
            <Route
              path="/survey-test-info/:sid"
              element={
                <Suspense fallback={<PreLoader isLoading />}>
                  <SurveyInfoTest />
                </Suspense>
              }
            />
            <Route
              path="/test-user-survey-info/:id/:sid"
              element={
                <Suspense fallback={<PreLoader isLoading />}>
                  <SurveyInfoUserTest />
                </Suspense>
              }
            />
            <Route
              path="/test-survey/:sid"
              element={
                <Suspense fallback={<PreLoader isLoading />}>
                  <SurveyTest />
                </Suspense>
              }
            />
            <Route
              path="/survey-answer/:sid"
              element={
                <Suspense fallback={<PreLoader isLoading />}>
                  <ResultAnswer />
                </Suspense>
              }
            />
            <Route
              path="/login"
              element={
                <Suspense fallback={<PreLoader isLoading />}>
                  <TestLogin />
                </Suspense>
              }
            />
            <Route
              path="/result-test"
              element={
                <Suspense fallback={<PreLoader isLoading />}>
                  <ResultTest />
                </Suspense>
              }
            />
            <Route path="*" element={<>There is no page</>} />
          </Routes>
        </BrowserRouter>
      </StyledWrapper>
    </MyThemeProvider>
  );
}

export default App;
const StyledWrapper = styled.div`
  height: 100%;
  /* display: flex;
  flex-direction: column;
  @media (min-width: ${(p) => p.theme.screenWidthMd}) {
    flex-direction: row;
  } */
`;
