import React from "react";
const SelectBox = React.forwardRef(
  ({ placeholder, items, selected, ...rest }, ref) => (
    <select className="inp-2" {...rest} ref={ref}>
      {/* {hiddenValue && <option disabled value >{hiddenValue}</option>} */}
      {/* <option key='blankKey' hidden value >{placeholder}</option> */}
      <option value="" style={{display:'none'}}>{placeholder}</option>
      {items.map((itm, i) => {
        return (
          <option value={itm.value} key={i}>
            {itm.label}
          </option>
        );
      })}
    </select>
  )
);
export default SelectBox;
