import React, { useState, useImperativeHandle, useRef } from "react";
import { useForm } from "react-hook-form";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import {
  changeMatrixRadioError,
  changeMatrixCheckError,
} from "../../redux/matrix";
import { changeAnswer, addToSurveyList } from "../../redux/survey";
import {
  CheckboxWidget,
  TextWidget,
  RadioWidget,
  MatrixWidget,
  TextareaWidget,
  NumberWidget,
  DateWidget,
  DateTimeWidget,
  MatrixCheckWidget,
} from "..";
import { Link } from "react-router-dom";
import { BACKTO_DASHBOARD_URL } from "../../utils/constUrl";
export default function WizardNestedForm({
  results,
  seconds,
  minutes,
  hours,
  questionTimer,
  data,
  reference,
  testMode,
}) {
  const dispatch = useDispatch();
  const { matrixList, matrixCheckList } = useSelector((state) => state.matrix);
  const { surveyList } = useSelector((state) => state.survey);
  const [matrixRadio, setMatrixRadio] = useState([]);
  const [matrixCheck, setMatrixCheck] = useState([]);
  const [current, setCurrent] = useState(0);
  const [maxSection, setMaxSection] = useState(0);
  const [attendedCount, setAttendedCount] = useState(0);
  const formBtnRef = useRef();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
    resetField,
  } = useForm({
    shouldUseNativeValidation: false,
  });
  React.useEffect(() => {
    setMaxSection(data !== null ? data.pool.length : 0);
    setAttendedCount(data !== null ? data.total_attend_user_question_count : 0);
  }, []);
  React.useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name !== undefined) {
        let nameSplit = name.split("-");
        // console.log(name, "===", nameSplit[1], nameSplit[2], nameSplit[4]);
        inputAnswer(
          value[name],
          nameSplit[1],
          nameSplit[2],
          nameSplit[3],
          nameSplit[4]
        );
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);
  useImperativeHandle(reference, () => ({
    submitForm() {
      handleSubmit(autoSave)();
    },
  }));

  const inputAnswer = async (ans, sqmId, sectionId, pk, qType) => {
    if (qType === "Checkbox") {
      let cItem = surveyList.filter(
        (itm) =>
          itm.qid === Number(pk) &&
          itm.sqmId === Number(sqmId) &&
          itm.sId === Number(sectionId)
      );
      if (cItem.length > 0) {
        if (
          cItem[0].ans === null ||
          cItem[0].ans === "" ||
          cItem[0].ans.length === 0
        ) {
          dispatch(
            changeAnswer({
              sqmId: sqmId,
              sectionId: sectionId === null ? 0 : sectionId,
              qid: pk,
              ans: ans,
              checkBox: true,
            })
          );
        } else {
          dispatch(
            changeAnswer({
              sqmId: sqmId,
              sectionId: sectionId === null ? 0 : sectionId,
              qid: pk,
              ans: ans,
              checkBox: true,
            })
          );
        }
      } else {
        await dispatch(
          changeAnswer({
            sqmId: sqmId,
            sectionId: sectionId,
            qid: pk,
            ans: ans,
            checkBox: true,
          })
        );
      }
    } else {
      await dispatch(
        changeAnswer({
          sqmId: sqmId,
          sectionId: sectionId,
          qid: pk,
          ans: ans,
          checkBox: false,
        })
      );
    }
  };
  const autoSave = () => {
    // e.preventDefault();
    if (matrixCheckValidation() && matrixRadioValidation()) {
      results(answerFinder(), true, reset);
    } else {
      console.log("validation error");
    }
  };
  const save = (formRes, e) => {
    e.preventDefault();
    if (matrixCheckValidation() && matrixRadioValidation()) {
      if (questionTimer) {
        if (hours > 0 || minutes > 0 || seconds > 0) {
          if (current + 1 === maxSection) {
            results(answerFinder(formRes), true, reset);
          } else {
            // reset();
            // setCurrent(current + 1);
          }
        } else {
          results(answerFinder(formRes), true, reset);
        }
      } else {
        if (current + 1 === maxSection) {
          results(answerFinder(formRes), true, reset);
        } else {
          // reset();
          // setCurrent(current + 1);
        }
      }
    } else {
      console.log("validation error");
    }
  };
  const answerFinder = (formData) => {
    let ans = surveyList.map((itm) => {
      return {
        s_q_m_id: Number(itm.sqmId),
        qid: Number(itm.qid),
        sid: Number(itm.sId),
        ans: Array.isArray(itm.ans)
          ? itm.ans.length > 0
            ? itm.ans
            : []
          : itm.ans === null
          ? []
          : [itm.ans],
        matrix: "",
      };
    });
    let mat = [...matrixCheckList, ...matrixList].map((itm) => {
      return {
        s_q_m_id: itm.sqmId,
        qid: itm.qid,
        sid: itm.sId,
        ans: "",
        matrix: itm.data.map((mItm) => {
          return {
            r_id: mItm.rowId,
            ans_id: Array.isArray(mItm.ans)
              ? mItm.ans.length > 0
                ? mItm.ans
                : []
              : mItm.ans === null
              ? []
              : [mItm.ans],
          };
        }),
      };
    });
    return [...ans.filter((itm) => itm !== null && itm !== undefined), ...mat];
  };
  const prevForm = () => {
    // reset();
    setCurrent(current - 1);
  };
  const singleSaveAndNext = async () => {
    nextQuestion();
    if (matrixCheckValidation() && matrixRadioValidation()) {
      await results(answerFinder(), false);
    } else {
      console.log("validation error");
    }
  };
  const nextForm = async () => {
    // console.log(data.question_bank[current]);
    let currentData = data.pool[current];
    let vSid = currentData.Section_id_id;
    let vSqmId = currentData.pk;
    let vQid = currentData.question_details[0].pk;
    let vValid = currentData.question_details[0].manadatory;
    if (vValid) {
      if (currentData.question_details[0].Question_type === "Matrix_type") {
        if (currentData.question_details[0].matrix_type === "one_choice") {
          let cMitem = matrixList.filter(
            (itm) =>
              itm.qid === vQid && itm.sqmId === vSqmId && itm.sId === vSid
          );
          let nullAnswers = cMitem[0].data.filter(
            (ansArr) => ansArr.ans == null
          );
          if (nullAnswers.length > 0) {
            await dispatch(
              changeMatrixRadioError({
                qid: vQid,
                rid: nullAnswers.map((itms) => {
                  return itms.rowId;
                }),
              })
            );
          } else {
            singleSaveAndNext();
          }
        } else {
          let cMCitem = matrixCheckList.filter(
            (itm) =>
              itm.qid === vQid && itm.sqmId === vSqmId && itm.sId === vSid
          );
          let nullAnswers = cMCitem[0].data.filter(
            (ansArr) => ansArr.ans == null || ansArr.ans.length === 0
          );
          if (nullAnswers.length > 0) {
            await dispatch(
              changeMatrixCheckError({
                qid: vQid,
                rid: nullAnswers.map((itms) => {
                  return itms.rowId;
                }),
              })
            );
          } else {
            singleSaveAndNext();
          }
        }
      } else {
        let cItem = surveyList.filter(
          (itm) => itm.qid === vQid && itm.sqmId === vSqmId && itm.sId === vSid
        );
        // console.log(currentData, vSid, vSqmId, vQid, vValid);
        if (cItem.length > 0) {
          if (Array.isArray(cItem[0].ans)) {
            cItem[0].ans.length > 0 && singleSaveAndNext();
          } else {
            if (cItem[0].ans !== null && cItem[0].ans !== "") {
              singleSaveAndNext();
            }
          }
          // cItem[0].ans === null ? "" : cItem[0].ans;
        } else {
          singleSaveAndNext();
        }
      }
    } else {
      singleSaveAndNext();
    }
  };
  const nextQuestion = () => {
    setCurrent(current + 1);
  };
  const matrixCheckValidation = () => {
    if (matrixCheck.length > 0) {
      let valTrue = 0;
      matrixCheck.map((itm) => {
        matrixCheckList.map(async (mq) => {
          if (mq.qid == itm) {
            let nullAnswers = mq.data.filter((ansArr) => ansArr.ans == null);
            if (nullAnswers.length > 0) {
              valTrue = valTrue + 1;
              await dispatch(
                changeMatrixCheckError({
                  qid: mq.qid,
                  rid: nullAnswers.map((itms) => {
                    return itms.rowId;
                  }),
                })
              );
            }
          }
        });
      });
      return valTrue > 0 ? false : true;
    } else {
      return true;
    }
  };
  const matrixRadioValidation = () => {
    if (matrixRadio.length > 0) {
      let valTrue = 0;
      matrixRadio.map((itm) => {
        matrixList.map(async (mq) => {
          if (mq.qid == itm) {
            let nullAnswers = mq.data.filter((ansArr) => ansArr.ans == null);
            if (nullAnswers.length > 0) {
              valTrue = valTrue + 1;
              await dispatch(
                changeMatrixRadioError({
                  qid: mq.qid,
                  rid: nullAnswers.map((itms) => {
                    return itms.rowId;
                  }),
                })
              );
            }
            // console.log(nullAnswers);
          }
        });
      });
      return valTrue > 0 ? false : true;
    } else {
      return true;
    }
  };
  const lengthValidation = (question) => {
    if (question.validate_entry) {
      return {
        minLength: question.min_validate_entry,
        maxLength: question.max_validate_entry,
      };
    }
  };

  const dynamicWidget = (question, sectionId, sqmId, itmI) => {
    switch (question.Question_type) {
      case "Radio":
        return (
          itmI === current && (
            <div key={itmI}>
              <RadioWidget
                isHorizontal={data.choice_type == 1 ? true : false}
                question={question}
                sequence={
                  data.Sequence_number
                    ? data.total_attend_user_question_count + itmI + 1
                    : ""
                }
                language={data.language_id}
                sqmId={sqmId}
                sectionId={sectionId}
                name={`input-${sqmId}-${sectionId}-${question.pk}-${question.Question_type}`}
                {...register(
                  `input-${sqmId}-${sectionId}-${question.pk}-${question.Question_type}`,
                  {
                    required: {
                      value: question.manadatory,
                      message: "Please enter your input",
                    },
                  }
                )}
                inputError={
                  Object.keys(errors).length === 0
                    ? null
                    : errors[
                        `input-${sqmId}-${sectionId}-${question.pk}-${question.Question_type}`
                      ]
                }
              />
            </div>
          )
        );
      case "Checkbox":
        return (
          itmI === current && (
            <div key={itmI}>
              <CheckboxWidget
                isHorizontal={data.choice_type == 1 ? true : false}
                question={question}
                sequence={
                  data.Sequence_number
                    ? data.total_attend_user_question_count + itmI + 1
                    : ""
                }
                language={data.language_id}
                sqmId={sqmId}
                sectionId={sectionId}
                name={`input-${sqmId}-${sectionId}-${question.pk}-${question.Question_type}`}
                {...register(
                  `input-${sqmId}-${sectionId}-${question.pk}-${question.Question_type}`,
                  {
                    required: {
                      value: question.manadatory,
                      message: "Please enter your input",
                    },
                  }
                )}
                inputError={
                  Object.keys(errors).length === 0
                    ? null
                    : errors[
                        `input-${sqmId}-${sectionId}-${question.pk}-${question.Question_type}`
                      ]
                }
              />
            </div>
          )
        );
      case "multiple_line":
        return (
          itmI === current && (
            <div key={itmI}>
              <TextareaWidget
                // resteField={resetField}
                language={data.language_id}
                question={question}
                sequence={
                  data.Sequence_number
                    ? data.total_attend_user_question_count + itmI + 1
                    : ""
                }
                sqmId={sqmId}
                sectionId={sectionId}
                name={`input-${sqmId}-${sectionId}-${question.pk}-${question.Question_type}`}
                {...register(
                  `input-${sqmId}-${sectionId}-${question.pk}-${question.Question_type}`,
                  {
                    required: {
                      value: question.manadatory,
                      message: "Please enter your input",
                    },
                    validate: (value, formValues) =>
                      question.manadatory ? value.trim() !== "" : true,
                  }
                )}
                inputError={
                  Object.keys(errors).length === 0
                    ? null
                    : errors[
                        `input-${sqmId}-${sectionId}-${question.pk}-${question.Question_type}`
                      ]
                }
              />
            </div>
          )
        );
      case "text":
        if (question.input_email) {
          return (
            itmI === current && (
              <div key={itmI}>
                <TextWidget
                  // resteField={resetField}
                  language={data.language_id}
                  question={question}
                  sequence={
                    data.Sequence_number
                      ? data.total_attend_user_question_count + itmI + 1
                      : ""
                  }
                  sqmId={sqmId}
                  sectionId={sectionId}
                  name={`input-${sqmId}-${sectionId}-${question.pk}-${question.Question_type}`}
                  {...register(
                    `input-${sqmId}-${sectionId}-${question.pk}-${question.Question_type}`,
                    {
                      required: {
                        value: question.manadatory,
                        message: "Please enter your input",
                        pattern:
                          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        // ...lengthValidation(question),
                      },
                    }
                  )}
                  type="email"
                  inputError={
                    Object.keys(errors).length === 0
                      ? null
                      : errors[
                          `input-${sqmId}-${sectionId}-${question.pk}-${question.Question_type}`
                        ]
                  }
                />
              </div>
            )
          );
        } else {
          // console.log("dnbhdhdg", question.validate_entry);
          return (
            itmI === current && (
              <div key={itmI}>
                <TextWidget
                  // resteField={resetField}
                  language={data.language_id}
                  question={question}
                  sequence={
                    data.Sequence_number
                      ? data.total_attend_user_question_count + itmI + 1
                      : ""
                  }
                  sqmId={sqmId}
                  sectionId={sectionId}
                  name={`input-${sqmId}-${sectionId}-${question.pk}-${question.Question_type}`}
                  {...lengthValidation(question)}
                  {...register(
                    `input-${sqmId}-${sectionId}-${question.pk}-${question.Question_type}`,
                    {
                      required: {
                        value: question.manadatory,
                        message: "Please enter your input",
                      },
                      validate: (value, formValues) =>
                        question.manadatory ? value.trim() !== "" : true,
                    }
                  )}
                  inputError={
                    Object.keys(errors).length === 0
                      ? null
                      : errors[
                          `input-${sqmId}-${sectionId}-${question.pk}-${question.Question_type}`
                        ]
                  }
                />
              </div>
            )
          );
        }
      case "num_text":
        return (
          itmI === current && (
            <div key={itmI}>
              <NumberWidget
                // resteField={resetField}
                language={data.language_id}
                question={question}
                sequence={
                  data.Sequence_number
                    ? data.total_attend_user_question_count + itmI + 1
                    : ""
                }
                sqmId={sqmId}
                sectionId={sectionId}
                name={`input-${sqmId}-${sectionId}-${question.pk}-${question.Question_type}`}
                {...register(
                  `input-${sqmId}-${sectionId}-${question.pk}-${question.Question_type}`,
                  {
                    required: {
                      value: question.manadatory,
                      message: "Please enter your input",
                    },
                  }
                )}
                inputError={
                  Object.keys(errors).length === 0
                    ? null
                    : errors[
                        `input-${sqmId}-${sectionId}-${question.pk}-${question.Question_type}`
                      ]
                }
              />
            </div>
          )
        );
      case "Date_type":
        return (
          itmI === current && (
            <div key={itmI}>
              <DateWidget
                language={data.language_id}
                question={question}
                sequence={
                  data.Sequence_number
                    ? data.total_attend_user_question_count + itmI + 1
                    : ""
                }
                sqmId={sqmId}
                sectionId={sectionId}
                name={`input-${sqmId}-${sectionId}-${question.pk}-${question.Question_type}`}
                {...register(
                  `input-${sqmId}-${sectionId}-${question.pk}-${question.Question_type}`,
                  {
                    required: {
                      value: question.manadatory,
                      message: "Please enter your input",
                    },
                  }
                )}
                inputError={
                  Object.keys(errors).length === 0
                    ? null
                    : errors[
                        `input-${sqmId}-${sectionId}-${question.pk}-${question.Question_type}`
                      ]
                }
              />
            </div>
          )
        );
      case "Datetime_type":
        return (
          itmI === current && (
            <div key={itmI}>
              <DateTimeWidget
                language={data.language_id}
                question={question}
                sequence={
                  data.Sequence_number
                    ? data.total_attend_user_question_count + itmI + 1
                    : ""
                }
                sqmId={sqmId}
                sectionId={sectionId}
                name={`input-${sqmId}-${sectionId}-${question.pk}-${question.Question_type}`}
                {...register(
                  `input-${sqmId}-${sectionId}-${question.pk}-${question.Question_type}`,
                  {
                    required: {
                      value: question.manadatory,
                      message: "Please enter your input",
                    },
                  }
                )}
                inputError={
                  Object.keys(errors).length === 0
                    ? null
                    : errors[
                        `input-${sqmId}-${sectionId}-${question.pk}-${question.Question_type}`
                      ]
                }
              />
            </div>
          )
        );
      case "Matrix_type":
        if (question.matrix_type === "one_choice") {
          return (
            itmI === current && (
              <div key={itmI}>
                <MatrixWidget
                  language={data.language_id}
                  question={question}
                  sequence={
                    data.Sequence_number
                      ? data.total_attend_user_question_count + itmI + 1
                      : ""
                  }
                  setMatrixRadio={setMatrixRadio}
                  sectionId={sectionId}
                  sqmId={sqmId}
                />
              </div>
            )
          );
        } else {
          return (
            itmI === current && (
              <div key={itmI}>
                <MatrixCheckWidget
                  language={data.language_id}
                  question={question}
                  sequence={
                    data.Sequence_number
                      ? data.total_attend_user_question_count + itmI + 1
                      : ""
                  }
                  setMatrixCheck={setMatrixCheck}
                  sectionId={sectionId}
                  sqmId={sqmId}
                />
              </div>
            )
          );
        }
    }
  };

  // console.log(`${qMinutes}:${qSeconds}`);
  const languageStyles = () => {
    switch (data.language_id) {
      case "1":
        return {
          formType: "ltr",
          inputlabel: "choice_en",
          question: "question_en",
          choice: "choice_en",
        };
      case "2":
        return {
          formType: "rtl",
          inputlabel: "choice_ar",
          question: "question_ar",
          choice: "choice_ar",
        };
      case "3":
        return {
          formType: "rtl",
          inputlabel: "choice_ur",
          question: "question_ur",
          choice: "choice_ur",
        };
      case "4":
        return {
          formType: "ltr",
          inputlabel: "choice_ta",
          question: "question_ta",
          choice: "choice_ta",
        };
      case "5":
        return {
          formType: "ltr",
          inputlabel: "choice_hi",
          question: "question_hi",
          choice: "choice_hi",
        };
      default:
        return {
          formType: "ltr",
          inputlabel: "choice_en",
          question: "question_en",
          choice: "choice_en",
        };
    }
  };
  return (
    <div className="container-fluid px-0">
      <div id="form_container">
        <div className="row no-gutters">
          <div className="col-lg-12">
            <div className="top-bg d-flex align-items-center justify-content-between flex-md-row flex-column">
              <div className="exam-heading d-flex align-items-center">
                {/* <i
                  className="fa-solid fa-angle-left backicn"
                  //   onClick={() => navigate(-1)}
                ></i> */}
                <div>
                  <h2>{data.exam_title}</h2>
                  {data.information_list.map((itm, i) => (
                    <h6>
                      {itm.title}: {itm.answer}
                    </h6>
                  ))}

                  {/* <h6>Maximum Score: {data.exam_total_mark}</h6>
                  <h6>Total Questions: {maxSection}</h6> */}
                </div>
              </div>
              <div className="total-tym">
                {testMode && (
                  <Link to={BACKTO_DASHBOARD_URL}>
                    <button type="button" className="dashboard-class ml-2">
                      Back to Dashboard
                    </button>
                  </Link>
                )}
                {questionTimer && (
                  <h6 className="d-flex align-items-end">
                    Total Time Left:
                    <div id="countdown">
                      {hours > 0 ? hours : 0}:{minutes > 0 ? minutes : 0}:
                      {seconds > 0 ? seconds : 0}
                    </div>
                  </h6>
                )}
              </div>
            </div>
            <div dir={languageStyles().formType}>
              <form onSubmit={handleSubmit(save)}>
                <div className="m-5">
                  <div id="middle-wizard">
                    {data != null &&
                      data.pool.map((itm, itmI) => {
                        return dynamicWidget(
                          itm.question_details[0],
                          itm.Section_id_id,
                          itm.pk,
                          itmI
                        );
                      })}
                    {/* {data != null &&
                      dynamicWidget(
                        data.question_bank[current].question_details[0],
                        data.question_bank[current].Section_id_id,
                        data.question_bank[current].pk
                      )} */}
                  </div>
                  <div id="bottom-wizard">
                    {current !== 0 && (
                      <button
                        type="button"
                        name="backward"
                        className="backward"
                        onClick={prevForm}
                      >
                        Prev
                      </button>
                    )}
                    {/* {maxSection !== current + 1 && (
                      <button
                        style={{ display: "none" }}
                        type="submit"
                        name="forward"
                        className="forward mx-1"
                        ref={formBtnRef}
                      >
                        Next
                      </button>
                    )} */}

                    {maxSection !== current + 1 && (
                      <button
                        type="submit"
                        name="forward"
                        className="forward mx-1"
                        // ref={formBtnRef}
                        onClick={nextForm}
                      >
                        Next
                      </button>
                    )}
                    {maxSection === current + 1 && (
                      <button
                        type="submit"
                        name="process"
                        className="submit mx-1 submit-cbtn"
                        ref={formBtnRef}
                      >
                        Submit
                      </button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div id="top-wizard">
          <div
            id="progressbar"
            style={{
              padding: current + attendedCount === 0 ? 0 : 4,
              backgroundColor: "#351A5C",
              width: `${
                ((current + attendedCount) / (maxSection + attendedCount)) * 100
              }%`,
            }}
          ></div>
          {data.progress === "Percentage" && (
            <span id="location">
              {`${(
                ((current + attendedCount) / (maxSection + attendedCount)) *
                100
              ).toFixed()}%`}{" "}
              completed
            </span>
          )}
          {data.progress === "Number" && (
            <span id="location">
              {current + attendedCount} of {maxSection + attendedCount} |{" "}
              {current + attendedCount} completed
            </span>
          )}
        </div>
      </div>
    </div>
  );
}

// const RadioWidgetCu =({question, sectionId, sqmId})=>{
//   return (
//     <div className="step">
//       <label className="custom add_top_10"></label>
//       <h3 className="main_question">
//         <i className="arrow_right"></i>
//         {question[languageStyles().question]}
//       </h3>
//       <div className="row d-flex justify-content-center">
//         {question.multiple_choice.map((itm, i) => {
//           return (
//             <div className="col-lg-3" key={i}>
//               {i == 0 && radCheck !== null && radCheck !== undefined && (
//                 <span htmlFor="question_2" className="error">
//                   Required
//                 </span>
//               )}
//               <div className="form-group">
//                 <label className="container_radio version_2">
//                   {itm[languageStyles().choice]}
//                   <input
//                     type="radio"
//                     className="required"
//                     name={`input-${sqmId}-${sectionId}-${question.pk}-${question.Question_type}`}
//                     value={itm.id}
//                     onChange={(e) =>
//                       inputAnswer(
//                         e.target.value,
//                         sqmId,
//                         sectionId,
//                         question.pk
//                       )
//                     }
//                     {...register(
//                       `input-${sqmId}-${sectionId}-${question.pk}-${question.Question_type}`,
//                       {
//                         required: {
//                           value: question.manadatory,
//                           message: "Please enter your input",
//                         },
//                       }
//                     )}
//                   />
//                   <span className="checkmark"></span>
//                 </label>
//               </div>
//             </div>
//           );
//         })}
//       </div>
//     </div>
//   );
// }
