import React, { useEffect } from "react";
import { CheckBox } from "../";
import { useDispatch, useSelector } from "react-redux";
import { changeAnswer, addToSurveyList } from "../../redux/survey";
import { MEDIA_URL } from "../../utils/constUrl";
const CheckboxWidget = React.forwardRef(
  (
    {
      inputError,
      question,
      language,
      sectionId,
      sqmId,
      sequence,
      isHorizontal,
      ...rest
    },
    ref
  ) => {
    const languageStyles = () => {
      switch (language) {
        case "1":
          return {
            formType: "ltr",
            inputlabel: "choice_en",
            question: "question_en",
            choice: "choice_en",
          };
        case "2":
          return {
            formType: "rtl",
            inputlabel: "choice_ar",
            question: "question_ar",
            choice: "choice_ar",
          };
        case "3":
          return {
            formType: "rtl",
            inputlabel: "choice_ur",
            question: "question_ur",
            choice: "choice_ur",
          };
        case "4":
          return {
            formType: "ltr",
            inputlabel: "choice_ta",
            question: "question_ta",
            choice: "choice_ta",
          };
        case "5":
          return {
            formType: "ltr",
            inputlabel: "choice_hi",
            question: "question_hi",
            choice: "choice_hi",
          };
        default:
          return {
            formType: "ltr",
            inputlabel: "choice_en",
            question: "question_en",
            choice: "choice_en",
          };
      }
    };
    const dispatch = useDispatch();
    const { surveyList } = useSelector((state) => state.survey);
    useEffect(() => {
      dispatch(
        addToSurveyList({
          sqmId,
          sId: sectionId === null ? 0 : sectionId,
          qid: question.pk,
          ans: [],
          required: question.manadatory,
        })
      );
    }, [question.pk]);
    const inputAnswer = (ans) => {
      let cItem = surveyList.filter(
        (itm) =>
          itm.qid === question.pk &&
          itm.sqmId === sqmId &&
          itm.sId === sectionId
      );
      if (cItem.length > 0) {
        if (
          cItem[0].ans === null ||
          cItem[0].ans === "" ||
          cItem[0].ans.length === 0
        ) {
          dispatch(
            changeAnswer({
              sqmId: sqmId,
              sectionId: sectionId === null ? 0 : sectionId,
              qid: question.pk,
              ans: [ans],
            })
          );
        } else {
          if (cItem[0].ans.includes(ans)) {
            let newAns = cItem[0].ans.filter((itm) => itm !== ans);
            dispatch(
              changeAnswer({
                sqmId: sqmId,
                sectionId: sectionId === null ? 0 : sectionId,
                qid: question.pk,
                ans: newAns.length > 0 ? newAns : null,
              })
            );
          } else {
            dispatch(
              changeAnswer({
                sqmId: sqmId,
                sectionId: sectionId === null ? 0 : sectionId,
                qid: question.pk,
                ans: [...cItem[0].ans, ans],
              })
            );
          }
        }
      }
    };
    return (
      <div className="step">
        <h3 className="main_question">
          <i className="arrow_right"></i>
          {sequence && sequence + ". "}
          {question[languageStyles().question]}
        </h3>
        {question.Description !== "" && <small>{question.Description}</small>}
        {question.comments !== "" && <small>{question.comments}</small>}
        {/* <small>* Multiple answers allowed </small> */}
        {question.multiple_image.length > 0 && (
          <div className="d-flex justify-content-center">
            {question.multiple_image.map((imgItm, im) => {
              return (
                <div className="qimg-box" key={im}>
                  <img
                    src={MEDIA_URL + imgItm.file_data}
                    className="img-fluid img-center"
                  />
                </div>
              );
            })}
          </div>
        )}
        <div
          className={`row d-flex ${
            isHorizontal ? "flex-row" : "flex-column"
          } justify-content-center align-items-center`}
        >
          {question.multiple_choice.map((itm, i) => {
            return (
              <div className="col-lg-3" key={i}>
                <div className="form-group">
                  {i == 0 && inputError !== null && inputError !== undefined && (
                    <span htmlFor="question_2" className="error">
                      Required
                    </span>
                  )}
                  <CheckBox
                    label={itm[[languageStyles().choice]]}
                    value={itm.id}
                    // onChange={(e) => inputAnswer(e.target.value)}
                    ref={ref}
                    img={
                      itm.Imagefield !== "" && itm.Imagefield !== null
                        ? MEDIA_URL + itm.Imagefield
                        : ""
                    }
                    {...rest}
                  />
                </div>
              </div>
            );
          })}
          {/* <div className="col-lg-3">
          <div className="form-group">
            <CheckBox label="Europe" name="question_1" value="Europe" ref={ref} {...rest} />
          </div>
          <div className="form-group">
            <CheckBox label="US States" name="question_1" value="US States" ref={ref} {...rest} />
          </div>
        </div> */}
        </div>
      </div>
    );
  }
);
export default CheckboxWidget;
