import React from "react";
import { useForm } from "react-hook-form";
export default function Login({ action, isLoading }) {
  const { register, handleSubmit } = useForm({
    shouldUseNativeValidation: true,
    // resolver: yupResolver(schema),
  });
  return (
    <form onSubmit={handleSubmit(action)}>
      <div className="row">
        <div className="col-lg-12 text-center">
          <h3 className="form-head">Login</h3>
        </div>
      </div>
      <div className="row d-flex justify-content-center">
        <div className="col-lg-4">
          <input
            type="text"
            className="inp-1"
            placeholder="Username"
            name="username"
            {...register("username", {
              required: true,
            })}
          />
        </div>
      </div>
      <div className="row d-flex justify-content-center">
        <div className="col-lg-4">
          <input
            type="password"
            className="inp-1"
            placeholder="Password"
            name="password"
            {...register("password", {
              required: true,
            })}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12 text-center">
          <button
            type="submit"
            className="submit-button-class"
            disabled={isLoading}
          >
            {isLoading ? (
              <i className="fa fa-spinner" aria-hidden="true"></i>
            ) : (
              "Login"
            )}
          </button>
        </div>
      </div>
    </form>
  );
}
