import React from "react";
import moment from "moment";
import { MEDIA_URL } from "../../utils/constUrl";
const DateTimeAnswer = ({ question, language,isDate }) => {
  const languageStyles = () => {
    switch (language) {
      case "1":
        return {
          formType: "ltr",
          inputlabel: "choice_en",
          question: "question_en",
          choice: "choice_en",
        };
      case "2":
        return {
          formType: "rtl",
          inputlabel: "choice_ar",
          question: "question_ar",
          choice: "choice_ar",
        };
      case "3":
        return {
          formType: "rtl",
          inputlabel: "choice_ur",
          question: "question_ur",
          choice: "choice_ur",
        };
      case "4":
        return {
          formType: "ltr",
          inputlabel: "choice_ta",
          question: "question_ta",
          choice: "choice_ta",
        };
      case "5":
        return {
          formType: "ltr",
          inputlabel: "choice_hi",
          question: "question_hi",
          choice: "choice_hi",
        };
      default:
        return {
          formType: "ltr",
          inputlabel: "choice_en",
          question: "question_en",
          choice: "choice_en",
        };
    }
  };
  return (
    <div className="col-lg-6" dir={languageStyles().formType}>
    <div className="exam-result-box">
      <div className="d-flex">
        <div className="question-box d-flex align-items-center mx-1">
          <p>{question[languageStyles().question]}</p>
        </div>
        <div className="mark-box d-flex align-items-center justify-content-center">
          <div>
            <h3>{question.user_total_mark}</h3>
            <p>Mark</p>
          </div>
        </div>
      </div>
      <div className="result-section">
        <div className="row">
          <div className="col-lg-12">
            <div className="answer-bg d-flex align-items-center">
              <p>
                {isDate
                  ? moment(question.custom_answ).format("DD-MM-YYYY")
                  : moment(question.custom_answ).format(
                      "DD-MM-YYYY hh:mm:ss a"
                    )}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  );
};
export default DateTimeAnswer;
