import { createSlice } from "@reduxjs/toolkit";
const INITIAL_STATE = {
  surveyList: [],
};
const surveySlice = createSlice({
  name: "survey",
  initialState: INITIAL_STATE,
  reducers: {
    addToSurveyList: (state, action) => {
      const itemExist = state.surveyList.find(
        (itm) =>
          itm.qid === action.payload.qid &&
          itm.sId === action.payload.sId &&
          itm.sqmId === action.payload.sqmId
      );
      if (!itemExist) {
        state.surveyList.push({ ...action.payload });
      }
    },
    changeAnswer: (state, action) => {
      let sqmId = Number(action.payload.sqmId);
      let sectionId = Number(action.payload.sectionId);
      let qid = Number(action.payload.qid);
      let ans = action.payload.ans;
      let checkBox = action.payload.checkBox;
      if (checkBox) {
        const ifExist = state.surveyList.filter(
          (itm) =>
            itm.sqmId === sqmId && itm.sId === sectionId && itm.qid === qid
        );
        if (ifExist.length <= 0) {
          state.surveyList.push({
            sqmId: sqmId,
            sId: sectionId,
            qid: qid,
            ans: ans,
            required: false,
          });
        }else {
          state.surveyList.forEach((itm) => {
            if (
              itm.sqmId === sqmId &&
              itm.sId === sectionId &&
              itm.qid === qid
            ) {
              itm.ans = ans;
            }
          });
        }
      } else {
        const ifExist = state.surveyList.filter(
          (itm) =>
            itm.sqmId === sqmId && itm.sId === sectionId && itm.qid === qid
        );
        if (ifExist.length <= 0) {
          state.surveyList.push({
            sqmId: sqmId,
            sId: sectionId,
            qid: qid,
            ans: ans,
            required: false,
          });
        } else {
          state.surveyList.forEach((itm) => {
            if (
              itm.sqmId === sqmId &&
              itm.sId === sectionId &&
              itm.qid === qid
            ) {
              if (Array.isArray(itm.ans)) {
                if (itm.ans.length > 0) {
                  if (itm.ans.includes(ans)) {
                    itm.ans = itm.ans.filter((itm) => itm !== ans);
                  }
                } else {
                  itm.ans = ans;
                }
              } else {
                itm.ans = ans;
              }
            }
          });
        }
      }
    },
    removeAllSurvey: (state, action) => {
      state.surveyList = [];
    },
  },
});
export const { changeAnswer, addToSurveyList, removeAllSurvey } =
  surveySlice.actions;
export default surveySlice.reducer;
