import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addToMatrixCheckList,
  changeMatrixCheckAnswer,
} from "../../redux/matrix";
import { MEDIA_URL } from "../../utils/constUrl";
const MatrixCheckWidget = ({
  inputError,
  question,
  setMatrixCheck,
  language,
  sectionId,
  sqmId,sequence,
}) => {
  const dispatch = useDispatch();
  const { matrixCheckList } = useSelector((state) => state.matrix);
  useEffect(() => {
    initalDataForStore();
  }, [question.pk]);
  // console.log(matrixCheckList, "matrixListmatrixListmatrixList");
  const initalDataForStore = async () => {
    question.manadatory && setMatrixCheck((prev) => [...prev, question.pk]);
    let matrixData = {
      qid: question.pk,
      sId: sectionId,
      sqmId: sqmId,
      data: dynamicMatrixData(),
    };
    // console.log(matrixData, "matrixData check");
    await dispatch(addToMatrixCheckList(matrixData));
  };
  const dynamicMatrixData = () => {
    return question.matrix_row.map((qitm, i) => {
      return {
        rowId: qitm.id,
        ans: null,
        error: false,
        required: question.manadatory,
      };
    });
  };
  const inputAnswer = async (ans, rowId) => {
    // console.log(ans, rowId);
    await dispatch(
      changeMatrixCheckAnswer({ qid: question.pk, ans: ans, rowId })
    );
  };
  const languageStyles = () => {
    switch (language) {
      case "1":
        return {
          formType: "ltr",
          inputlabel: "choice_en",
          question: "question_en",
          choice: "choice_en",
        };
      case "2":
        return {
          formType: "rtl",
          inputlabel: "choice_ar",
          question: "question_ar",
          choice: "choice_ar",
        };
      case "3":
        return {
          formType: "rtl",
          inputlabel: "choice_ur",
          question: "question_ur",
          choice: "choice_ur",
        };
      case "4":
        return {
          formType: "ltr",
          inputlabel: "choice_ta",
          question: "question_ta",
          choice: "choice_ta",
        };
      case "5":
        return {
          formType: "ltr",
          inputlabel: "choice_hi",
          question: "question_hi",
          choice: "choice_hi",
        };
      default:
        return {
          formType: "ltr",
          inputlabel: "choice_en",
          question: "question_en",
          choice: "choice_en",
        };
    }
  };
  // console.log(question,"------------------");

  const errorInMatrix = (id) => {
    let arrayQuest = matrixCheckList.filter((itm) => itm.qid === question.pk);
    if (arrayQuest.length > 0) {
      return arrayQuest[0].data.filter((itm) => itm.rowId == id)[0].error;
    }
  };
  const isChecked = (qId, rowId, itmId) => {
    let arrayQuest = matrixCheckList.filter(
      (itm) => itm.qid === qId && sectionId === itm.sId && sqmId === itm.sqmId
    );
    if (arrayQuest.length > 0) {
      let ans = arrayQuest[0].data.filter((itm) => itm.rowId == rowId)[0].ans;
      if (ans !== null) {
        if (ans.length > 0) {
          return ans.includes(itmId.toString());
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  };
  return (
    <div className="step">
      <h3 className="main_question">
        <i className="arrow_right"></i>
        {sequence&&sequence+". "}{question[languageStyles().question]}
      </h3>
      {question.Description !== "" && <small>{question.Description}</small>}
      {question.comments !== "" && <small>{question.comments}</small>}
      {question.multiple_image.length > 0 && (
        <div className="d-flex justify-content-center">
          {question.multiple_image.map((imgItm, im) => {
            return (
              <div className="qimg-box" key={im}>
                <img
                  src={MEDIA_URL + imgItm.file_data}
                  className="img-fluid img-center"
                />
              </div>
            );
          })}
        </div>
      )}
      <div className="row d-flex justify-content-center">
        <div className="col-md-8 mt-2 mb-3">
          <table className="table-bordered w-100 matrix-tab">
            <thead>
              <tr>
                <th></th>
                {question.matrix_answer.map((itm, i) => {
                  return <th key={i}>{itm[languageStyles().choice]}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {question.matrix_row.map((qitm, i) => {
                return (
                  <tr key={i}>
                    <th>{qitm[languageStyles().choice]}</th>
                    {question.matrix_answer.map((itm, i) => {
                      return (
                        <td key={i}>
                          <div className="d-flex">
                            {/* <h6>{itm.choice}</h6> */}
                            <label className="container_radio version_2">
                              {i === 0 && errorInMatrix(qitm.id) && (
                                <span htmlFor="question_2" className="error">
                                  Required
                                </span>
                              )}
                              <input
                                type="checkbox"
                                name={`question_${question.pk}_${qitm.id}`}
                                value={itm.id}
                                onChange={(e) =>
                                  inputAnswer(e.target.value, qitm.id)
                                }
                                defaultChecked={isChecked(
                                  question.pk,
                                  qitm.id,
                                  itm.id
                                )}
                                //   className="48option_value_matrix_radio"
                              />
                              <span className="checkmark"></span>
                            </label>

                            {/* <label className="container_radio version_2">
                                <input
                                  type="radio"
                                  name={`question_${question.pk}_${qitm.id}`}
                                  value={itm.id}
                                  className="required"
                                  ref={ref}
                                  //   required={question.manadatory}
                                  //   {...rest}
                                />
                                <span className="checkmark"></span>
                              </label> */}
                          </div>
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
export default MatrixCheckWidget;
