import React from "react";

const CheckBox = React.forwardRef(({ label, img, ...rest }, ref) => {
  return (
    <div className="form-group">
      <label className="container_check version_2">
        {label}
        {/* {img && <img src={img} className="img-fluid img-center" />} */}
        {img !== null && img !== "" && (
          <div
            style={{ backgroundImage: `url(${img})` }}
            className="option-img-bg"
          ></div>
        )}
        <input type="checkbox" className="required" ref={ref} {...rest} />
        <span className="checkmark"></span>
      </label>
    </div>
  );
});
export default CheckBox;
