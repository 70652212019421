import React, {
  useState,
  useImperativeHandle,
  useRef,
  useCallback,
} from "react";
import { useForm } from "react-hook-form";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import {
  changeMatrixRadioError,
  changeMatrixCheckError,
} from "../../redux/matrix";
import {
  changeAnswer,
  addToSurveyList,
  removeAllSurvey,
} from "../../redux/survey";
import { removeAllMatrix } from "../../redux/matrix";
import {
  CheckboxWidget,
  TextWidget,
  RadioWidget,
  MatrixWidget,
  TextareaWidget,
  NumberWidget,
  DateWidget,
  DateTimeWidget,
  MatrixCheckWidget,
  CircularProgress,
  Login,
} from "../";
import { Link } from "react-router-dom";
import { BACKTO_DASHBOARD_URL } from "../../utils/constUrl";
export default function WizardForm({
  results,
  seconds,
  minutes,
  hours,
  questionTimer,
  data,
  reference,
  examTimerModal,
  testMode,
}) {
  const dispatch = useDispatch();
  const { matrixList, matrixCheckList } = useSelector((state) => state.matrix);
  const { surveyList } = useSelector((state) => state.survey);
  const [matrixRadio, setMatrixRadio] = useState([]);
  const [matrixCheck, setMatrixCheck] = useState([]);
  const [oneQuestionTimer, setOneQuestionTimer] = useState(false);
  const [qDays, setQdays] = useState(0);
  const [qHours, setQHours] = useState(0);
  const [qMinutes, setQMinutes] = useState(0);
  const [qSeconds, setQSeconds] = useState(0);
  // let qMinutes=0;
  // let qSeconds=0;
  const [actualTime, setActualTime] = useState("0:0:0");
  const [current, setCurrent] = useState(0);
  const [maxSection, setMaxSection] = useState(0);
  const [attendedCount, setAttendedCount] = useState(0);
  const [, updateState] = useState();
  const handleForceupdateMethod = useCallback(() => updateState({}), []);
  const formBtnRef = useRef();
  const formRef = useRef();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
  } = useForm({
    shouldUseNativeValidation: false,
  });
  // console.log(errors);
  const qEndTime = localStorage.getItem("questionEndDateTime");
  React.useEffect(() => {
    // console.log(data.pool,data.pool.length,"data.pool.length");
    setMaxSection(data !== null ? data.pool.length : 0);
    setAttendedCount(data !== null ? data.total_attend_user_question_count : 0);
    settingStartTime();
  }, [current]);
  React.useEffect(() => {
    const increment = () => {
      datetimeDiff();
    };
    const intervalId = setInterval(increment, 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, [actualTime, oneQuestionTimer, current]);
  React.useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name !== undefined) {
        let nameSplit = name.split("-");
        // console.log(name, "===", nameSplit[1], nameSplit[2], nameSplit[3]);
        inputAnswer(value[name], nameSplit[1], nameSplit[2], nameSplit[3]);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);
  useImperativeHandle(reference, () => ({
    submitForm() {
      handleSubmit(autoSave)();
      // handleSubmit((d) => autoSave(d))
    },
  }));
  const settingStartTime = async () => {
    await localStorage.removeItem("questionEndDateTime");
    await localStorage.removeItem("questionStartDateTime");
    if (
      data.pool[current] !== undefined &&
      data.pool[current].question_details[0].question_time_status &&
      !examTimerModal
    ) {
      setOneQuestionTimer(true);
      let startDate = new Date();
      let Question_time_limit =
        data.pool[current].question_details[0].Question_time_limit;
      await setActualTime(Question_time_limit);
      // console.log(Question_time_limit,"Question_time_limit");
      let hr = Question_time_limit.split(":")[0];
      let min = Question_time_limit.split(":")[1];
      let sec = Question_time_limit.split(":")[2];
      let questionEndDateTime = moment(startDate)
        .add(hr, "hours")
        .add(min, "minutes")
        .add(sec, "seconds")
        .format("DD-MM-YYYY HH:mm:ss");
      localStorage.setItem(
        "questionStartDateTime",
        moment(startDate).format("DD-MM-YYYY HH:mm:ss")
      );
      await localStorage.setItem("questionEndDateTime", questionEndDateTime);
      // datetimeDiff();
    } else {
      setOneQuestionTimer(false);
    }
  };

  const datetimeDiff = async () => {
    const qEndTimes = localStorage.getItem("questionEndDateTime");
    let now = moment(new Date()).format("DD-MM-YYYY HH:mm:ss");
    let ms = moment(qEndTimes, "DD-MM-YYYY HH:mm:ss").diff(
      moment(now, "DD-MM-YYYY HH:mm:ss")
    );
    let d = moment.duration(ms);
    await setQdays(d.days());
    await setQHours(d.hours());
    await setQMinutes(d.minutes());
    await setQSeconds(d.seconds());
    // qMinutes=d.minutes();
    // qSeconds=d.seconds();
    //moment(moment(now).format("DD-MM-YYYY HH:mm:ss")).isAfter(moment(qEndTime).format("DD-MM-YYYY HH:mm:ss"))
    let dateTimes = [d.days(), d.hours(), d.minutes(), d.seconds()].filter(
      (number) => number < 0
    );
    if (
      dateTimes.length > 0 &&
      data.pool[current].question_details[0].question_time_status &&
      !examTimerModal
    ) {
      formBtnRef.current.click();
      // console.log(
      //   "your time finished",
      //   dateTimes.length,
      //   data.pool[current].question_details[0].question_time_status
      // );
    }
    // handleForceupdateMethod();
  };

  const inputAnswer = (ans, sqmId, sectionId, pk) => {
    dispatch(
      changeAnswer({
        sqmId: sqmId,
        sectionId: sectionId === null ? 0 : sectionId,
        qid: pk,
        ans: ans,
        checkBox: false,
      })
    );
  };
  const autoSave = (formRes) => {
    // e.preventDefault();
    // console.log(formRes, "rdrdrddr");
    if (matrixCheckValidation() && matrixRadioValidation()) {
      // console.log(
      //   "data.total_attend_user_question_count + 1",
      //   data.total_attend_user_question_count + 1
      // );
      results(answerFinder(formRes), true, reset);
    } else {
      console.log("validation error");
    }
  };
  // console.log(maxSection);
  const save = async (formRes, e) => {
    e.preventDefault();
    // console.log(formRes, "rdrdrddr");
    if (matrixCheckValidation() && matrixRadioValidation()) {
      if (questionTimer) {
        if (hours > 0 || minutes > 0 || seconds > 0) {
          await results(
            answerFinder(formRes),
            maxSection === current + 1,
            reset
          );
          formRef.current.reset();
        } else {
          await results(answerFinder(formRes), true, reset);
          formRef.current.reset();
        }
      } else {
        await results(answerFinder(formRes), maxSection === current + 1, reset);
        formRef.current.reset();
      }
      await dispatch(removeAllMatrix());
      await dispatch(removeAllSurvey());
      await setCurrent(current + 1);
    } else {
      console.log("validation error");
    }
  };
  const answerFinder = (formData) => {
    const keys = Object.keys(formData);
    const lastKey = keys[keys.length - 1];
    const lastValue = formData[lastKey];
    const lastKeyValuePair = { [lastKey]: lastValue };
    // console.log(formData, "formData");
    let ans = Object.keys(lastKeyValuePair).map((itm) => {
      let isExistanswer = surveyList.filter(
        (itmf) =>
          itmf.qid == Number(itm.split("-")[3]) &&
          itmf.sId == Number(itm.split("-")[2]) &&
          itmf.sqmId == Number(itm.split("-")[1])
      );
      if (isExistanswer.length > 0) {
        return {
          s_q_m_id: Number(itm.split("-")[1]),
          qid: Number(itm.split("-")[3]),
          sid: Number(itm.split("-")[2]),
          ans: Array.isArray(lastKeyValuePair[itm])
            ? lastKeyValuePair[itm].length > 0
              ? lastKeyValuePair[itm]
              : []
            : lastKeyValuePair[itm] == null
            ? []
            : typeof lastKeyValuePair[itm] == "boolean"
            ? []
            : [lastKeyValuePair[itm]],
          matrix: "",
        };
      } else {
        return {
          s_q_m_id: Number(itm.split("-")[1]),
          qid: Number(itm.split("-")[3]),
          sid: Number(itm.split("-")[2]),
          ans: [],
          matrix: "",
        };
      }
    });
    let mat = [...matrixCheckList, ...matrixList].map((itm) => {
      return {
        s_q_m_id: itm.sqmId,
        qid: itm.qid,
        sid: itm.sId,
        ans: "",
        matrix: itm.data.map((mItm) => {
          return {
            r_id: mItm.rowId,
            ans_id: Array.isArray(mItm.ans)
              ? mItm.ans.length > 0
                ? mItm.ans
                : []
              : mItm.ans === null
              ? []
              : [mItm.ans],
          };
        }),
      };
    });
    // console.log(...mat, "ffff", matrixList, matrixCheckList);
    let finalResult = [
      ...ans.filter((itm) => itm !== null && itm !== undefined),
      ...mat,
    ];
    return finalResult.filter((itms) => !isNaN(itms.s_q_m_id));
  };
  // console.log("ffff", matrixList, matrixCheckList);
  const matrixCheckValidation = () => {
    if (matrixCheck.length > 0) {
      let valTrue = 0;
      matrixCheck.map((itm) => {
        matrixCheckList.map(async (mq) => {
          if (mq.qid == itm) {
            let nullAnswers = mq.data.filter(
              (ansArr) => ansArr.ans == null || ansArr.ans.length === 0
            );
            if (nullAnswers.length > 0) {
              valTrue = valTrue + 1;
              await dispatch(
                changeMatrixCheckError({
                  qid: mq.qid,
                  rid: nullAnswers.map((itms) => {
                    return itms.rowId;
                  }),
                })
              );
            }
          }
        });
      });
      return valTrue > 0 ? false : true;
    } else {
      return true;
    }
  };
  const matrixRadioValidation = () => {
    if (matrixRadio.length > 0) {
      let valTrue = 0;
      matrixRadio.map((itm) => {
        matrixList.map(async (mq) => {
          if (mq.qid == itm) {
            let nullAnswers = mq.data.filter((ansArr) => ansArr.ans == null);
            if (nullAnswers.length > 0) {
              valTrue = valTrue + 1;
              await dispatch(
                changeMatrixRadioError({
                  qid: mq.qid,
                  rid: nullAnswers.map((itms) => {
                    return itms.rowId;
                  }),
                })
              );
            }
            // console.log(nullAnswers);
          }
        });
      });
      return valTrue > 0 ? false : true;
    } else {
      return true;
    }
  };
  const languageStyles = () => {
    switch (data.language_id) {
      case "1":
        return {
          formType: "ltr",
          inputlabel: "choice_en",
          question: "question_en",
          choice: "choice_en",
        };
      case "2":
        return {
          formType: "rtl",
          inputlabel: "choice_ar",
          question: "question_ar",
          choice: "choice_ar",
        };
      case "3":
        return {
          formType: "rtl",
          inputlabel: "choice_ur",
          question: "question_ur",
          choice: "choice_ur",
        };
      case "4":
        return {
          formType: "ltr",
          inputlabel: "choice_ta",
          question: "question_ta",
          choice: "choice_ta",
        };
      case "5":
        return {
          formType: "ltr",
          inputlabel: "choice_hi",
          question: "question_hi",
          choice: "choice_hi",
        };
      default:
        return {
          formType: "ltr",
          inputlabel: "choice_en",
          question: "question_en",
          choice: "choice_en",
        };
    }
  };
  // const radioButtons = async(question, sectionId, sqmId) => {
  //   // await dispatch(
  //   //   addToSurveyList({
  //   //     sqmId,
  //   //     sId: sectionId,
  //   //     qid: question.pk,
  //   //     ans: null,
  //   //     required: question.manadatory,
  //   //   })
  //   // );
  //   let radCheck =
  //     Object.keys(errors).length === 0
  //       ? null
  //       : errors[`input-${sqmId}-${sectionId}-${question.pk}`];

  //   return (
  //     <div className="step">
  //       <label className="custom add_top_10"></label>
  //       <h3 className="main_question">
  //         <i className="arrow_right"></i>
  //         {question[languageStyles().question]}
  //       </h3>
  //       <div className="row d-flex justify-content-center">
  //         {question.multiple_choice.map((itm, i) => {
  //           return (
  //             <div className="col-lg-3" key={i}>
  //               {i == 0 && radCheck !== null && radCheck !== undefined && (
  //                 <span htmlFor="question_2" className="error">
  //                   Required
  //                 </span>
  //               )}
  //               <div className="form-group">
  //                 <label className="container_radio version_2">
  //                   {itm[languageStyles().choice]}
  //                   <input
  //                     type="radio"
  //                     className="required"
  //                     name={`input-${sqmId}-${sectionId}-${question.pk}`}
  //                     value={itm.id}
  //                     onChange={(e) =>
  //                       inputAnswer(
  //                         e.target.value,
  //                         sqmId,
  //                         sectionId,
  //                         question.pk
  //                       )
  //                     }
  //                     {...register(
  //                       `input-${sqmId}-${sectionId}-${question.pk}`,
  //                       {
  //                         required: {
  //                           value: question.manadatory,
  //                           message: "Please enter your input",
  //                         },
  //                       }
  //                     )}
  //                   />
  //                   <span className="checkmark"></span>
  //                 </label>
  //               </div>
  //             </div>
  //           );
  //         })}
  //       </div>
  //     </div>
  //   );
  // };
  const lengthValidation = (question) => {
    if (question.validate_entry) {
      return {
        minLength: question.min_validate_entry,
        maxLength: question.max_validate_entry,
      };
    }
  };
  const dynamicWidget = (question, sectionId, sqmId) => {
    // console.log(question, sectionId);
    // console.log(data.pool[current].question_details[0],data.pool[current],"--------------");
    switch (question.Question_type) {
      case "Radio":
        return (
          <RadioWidget
            isHorizontal={data.choice_type == 1 ? true : false}
            question={question}
            sequence={
              data.Sequence_number
                ? data.total_attend_user_question_count + current + 1
                : ""
            }
            language={data.language_id}
            sqmId={sqmId}
            sectionId={sectionId}
            name={`input-${sqmId}-${sectionId}-${question.pk}`}
            {...register(`input-${sqmId}-${sectionId}-${question.pk}`, {
              required: {
                value: question.manadatory,
                message: "Please enter your input",
              },
            })}
            inputError={
              Object.keys(errors).length === 0
                ? null
                : errors[`input-${sqmId}-${sectionId}-${question.pk}`]
            }
          />
        );
      case "Checkbox":
        return (
          <CheckboxWidget
            isHorizontal={data.choice_type == 1 ? true : false}
            question={question}
            sequence={
              data.Sequence_number
                ? data.total_attend_user_question_count + current + 1
                : ""
            }
            language={data.language_id}
            sqmId={sqmId}
            sectionId={sectionId}
            name={`input-${sqmId}-${sectionId}-${question.pk}`}
            {...register(`input-${sqmId}-${sectionId}-${question.pk}`, {
              required: {
                value: question.manadatory,
                message: "Please enter your input",
              },
            })}
            inputError={
              Object.keys(errors).length === 0
                ? null
                : errors[`input-${sqmId}-${sectionId}-${question.pk}`]
            }
          />
        );
      case "multiple_line":
        return (
          <TextareaWidget
            language={data.language_id}
            question={question}
            sequence={
              data.Sequence_number
                ? data.total_attend_user_question_count + current + 1
                : ""
            }
            sqmId={sqmId}
            sectionId={sectionId}
            name={`input-${sqmId}-${sectionId}-${question.pk}`}
            {...register(`input-${sqmId}-${sectionId}-${question.pk}`, {
              required: {
                value: question.manadatory,
                message: "Please enter your input",
              },
              validate: (value, formValues) =>
                question.manadatory ? value.trim() !== "" : true,
            })}
            inputError={
              Object.keys(errors).length === 0
                ? null
                : errors[`input-${sqmId}-${sectionId}-${question.pk}`]
            }
          />
        );
      case "text":
        if (question.input_email) {
          return (
            <TextWidget
              language={data.language_id}
              question={question}
              sequence={
                data.Sequence_number
                  ? data.total_attend_user_question_count + current + 1
                  : ""
              }
              sqmId={sqmId}
              sectionId={sectionId}
              name={`input-${sqmId}-${sectionId}-${question.pk}`}
              {...lengthValidation(question)}
              {...register(`input-${sqmId}-${sectionId}-${question.pk}`, {
                required: {
                  value: question.manadatory,
                  message: "Please enter your input",
                  pattern:
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  // ...lengthValidation(question),
                },
              })}
              type="email"
              inputError={
                Object.keys(errors).length === 0
                  ? null
                  : errors[`input-${sqmId}-${sectionId}-${question.pk}`]
              }
            />
          );
        } else {
          return (
            <TextWidget
              language={data.language_id}
              question={question}
              sequence={
                data.Sequence_number
                  ? data.total_attend_user_question_count + current + 1
                  : ""
              }
              sqmId={sqmId}
              sectionId={sectionId}
              name={`input-${sqmId}-${sectionId}-${question.pk}`}
              {...lengthValidation(question)}
              {...register(`input-${sqmId}-${sectionId}-${question.pk}`, {
                required: {
                  value: question.manadatory,
                  message: "Please enter your input",
                },
                validate: (value, formValues) =>
                  question.manadatory ? value.trim() !== "" : true,
              })}
              inputError={
                Object.keys(errors).length === 0
                  ? null
                  : errors[`input-${sqmId}-${sectionId}-${question.pk}`]
              }
            />
          );
        }
      case "num_text":
        return (
          <NumberWidget
            language={data.language_id}
            question={question}
            sequence={
              data.Sequence_number
                ? data.total_attend_user_question_count + current + 1
                : ""
            }
            sqmId={sqmId}
            sectionId={sectionId}
            name={`input-${sqmId}-${sectionId}-${question.pk}`}
            {...register(`input-${sqmId}-${sectionId}-${question.pk}`, {
              required: {
                value: question.manadatory,
                message: "Please enter your input",
              },
            })}
            inputError={
              Object.keys(errors).length === 0
                ? null
                : errors[`input-${sqmId}-${sectionId}-${question.pk}`]
            }
          />
        );
      case "Date_type":
        return (
          <DateWidget
            language={data.language_id}
            question={question}
            sequence={
              data.Sequence_number
                ? data.total_attend_user_question_count + current + 1
                : ""
            }
            sqmId={sqmId}
            sectionId={sectionId}
            name={`input-${sqmId}-${sectionId}-${question.pk}`}
            {...register(`input-${sqmId}-${sectionId}-${question.pk}`, {
              required: {
                value: question.manadatory,
                message: "Please enter your input",
              },
            })}
            inputError={
              Object.keys(errors).length === 0
                ? null
                : errors[`input-${sqmId}-${sectionId}-${question.pk}`]
            }
          />
        );
      case "Datetime_type":
        return (
          <DateTimeWidget
            language={data.language_id}
            question={question}
            sequence={
              data.Sequence_number
                ? data.total_attend_user_question_count + current + 1
                : ""
            }
            sqmId={sqmId}
            sectionId={sectionId}
            name={`input-${sqmId}-${sectionId}-${question.pk}`}
            {...register(`input-${sqmId}-${sectionId}-${question.pk}`, {
              required: {
                value: question.manadatory,
                message: "Please enter your input",
              },
            })}
            inputError={
              Object.keys(errors).length === 0
                ? null
                : errors[`input-${sqmId}-${sectionId}-${question.pk}`]
            }
          />
        );
      case "Matrix_type":
        if (question.matrix_type === "one_choice") {
          return (
            <MatrixWidget
              language={data.language_id}
              question={question}
              sequence={
                data.Sequence_number
                  ? data.total_attend_user_question_count + current + 1
                  : ""
              }
              setMatrixRadio={setMatrixRadio}
              sectionId={sectionId}
              sqmId={sqmId}
              // name={`input-${question.pk}`}
              // {...register(`input-${question.pk}`, {
              //   required: {
              //     value: question.manadatory,
              //     message: "Please enter your input",
              //   },
              // })}
              // inputError={
              //   Object.keys(errors).length === 0
              //     ? null
              //     : errors[`input-${question.pk}`]
              // }
            />
          );
        } else {
          return (
            <MatrixCheckWidget
              language={data.language_id}
              question={question}
              sequence={
                data.Sequence_number
                  ? data.total_attend_user_question_count + current + 1
                  : ""
              }
              setMatrixCheck={setMatrixCheck}
              sectionId={sectionId}
              sqmId={sqmId}
              // name={`input-${question.pk}`}
              // {...register(`input-${question.pk}`, {
              //   required: {
              //     value: question.manadatory,
              //     message: "Please enter your input",
              //   },
              // })}
              // inputError={
              //   Object.keys(errors).length === 0
              //     ? null
              //     : errors[`input-${question.pk}`]
              // }
            />
          );
        }
    }
  };
  // console.log(`${qMinutes}:${qSeconds}`);
  return (
    <div className="container-fluid px-0">
      <div id="form_container">
        <div className="row no-gutters">
          <div className="col-lg-12">
            <div className="top-bg d-flex align-items-center justify-content-between flex-md-row flex-column">
              <div className="exam-heading d-flex align-items-center">
                {/* <i
                  className="fa-solid fa-angle-left backicn"
                  //   onClick={() => navigate(-1)}
                ></i> */}
                <div>
                  <h2>{data.exam_title}</h2>
                  {data.information_list.map((itm, i) => (
                    <h6 key={i}>
                      {itm.title}: {itm.answer}
                    </h6>
                  ))}
                  {/* <h6>Maximum Score: {data.exam_total_mark}</h6>
                  <h6>Total Questions: {data.total_questions_count}</h6> */}
                </div>
              </div>

              <div className="total-tym">
                {testMode && (
                  <Link to={BACKTO_DASHBOARD_URL}>
                    <button type="button" className="dashboard-class ml-2">
                      Back to Dashboard
                    </button>
                  </Link>
                )}
                {questionTimer && (
                  <h6 className="d-flex align-items-end">
                    Total Time Left:
                    <div id="countdown">
                      {hours > 0 ? hours : 0}:{minutes > 0 ? minutes : 0}:
                      {seconds > 0 ? seconds : 0}
                    </div>
                  </h6>
                )}
              </div>
            </div>

            {oneQuestionTimer && (
              <CircularProgress
                timer={`${qMinutes > 0 ? qMinutes : 0}:${
                  qSeconds > 0 ? qSeconds : 0
                }`}
                actualTime={`${
                  +actualTime.split(":")[0] * 60 * 60 +
                  +actualTime.split(":")[1] * 60 +
                  +actualTime.split(":")[2]
                }s`}
              />
            )}
            <div dir={languageStyles().formType}>
              <form onSubmit={handleSubmit(save)} ref={formRef}>
                <div className="m-5">
                  <div id="middle-wizard">
                    {data !== null &&
                      data.pool[current] !== undefined &&
                      dynamicWidget(
                        data.pool[current].question_details[0],
                        data.pool[current].Section_id_id,
                        data.pool[current].pk
                      )}
                  </div>
                  <div id="bottom-wizard">
                    {current + 1 !== 1 && data.back_button_status && (
                      <button
                        type="button"
                        name="backward"
                        className="backward"
                        // onClick={prev}
                      >
                        Prev
                      </button>
                    )}
                    {data.pool[current] !== undefined &&
                      maxSection !== current + 1 && (
                        <button
                          type="submit"
                          name="forward"
                          className="forward mx-1"
                          ref={formBtnRef}
                        >
                          Next
                        </button>
                      )}
                    {maxSection === current + 1 && (
                      <button
                        type="submit"
                        name="process"
                        className="submit mx-1 submit-cbtn"
                        ref={formBtnRef}
                      >
                        Submit
                      </button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div id="top-wizard">
          <div
            id="progressbar"
            style={{
              padding: current + attendedCount === 0 ? 0 : 4,
              backgroundColor: "#351A5C",
              width: `${
                ((current + attendedCount) / (maxSection + attendedCount)) * 100
              }%`,
            }}
          ></div>
          {data.progress === "Percentage" && (
            <span id="location">
              {`${(
                ((current + attendedCount) / (maxSection + attendedCount)) *
                100
              ).toFixed()}%`}{" "}
              completed
            </span>
          )}
          {data.progress === "Number" && (
            <span id="location">
              {current + attendedCount} of {maxSection + attendedCount} |{" "}
              {current + attendedCount} completed
            </span>
          )}
        </div>
      </div>
    </div>
  );
}

// const RadioWidgetCu =({question, sectionId, sqmId})=>{
//   return (
//     <div className="step">
//       <label className="custom add_top_10"></label>
//       <h3 className="main_question">
//         <i className="arrow_right"></i>
//         {question[languageStyles().question]}
//       </h3>
//       <div className="row d-flex justify-content-center">
//         {question.multiple_choice.map((itm, i) => {
//           return (
//             <div className="col-lg-3" key={i}>
//               {i == 0 && radCheck !== null && radCheck !== undefined && (
//                 <span htmlFor="question_2" className="error">
//                   Required
//                 </span>
//               )}
//               <div className="form-group">
//                 <label className="container_radio version_2">
//                   {itm[languageStyles().choice]}
//                   <input
//                     type="radio"
//                     className="required"
//                     name={`input-${sqmId}-${sectionId}-${question.pk}`}
//                     value={itm.id}
//                     onChange={(e) =>
//                       inputAnswer(
//                         e.target.value,
//                         sqmId,
//                         sectionId,
//                         question.pk
//                       )
//                     }
//                     {...register(
//                       `input-${sqmId}-${sectionId}-${question.pk}`,
//                       {
//                         required: {
//                           value: question.manadatory,
//                           message: "Please enter your input",
//                         },
//                       }
//                     )}
//                   />
//                   <span className="checkmark"></span>
//                 </label>
//               </div>
//             </div>
//           );
//         })}
//       </div>
//     </div>
//   );
// }
