import React, { useEffect } from "react";
import { MEDIA_URL } from "../../utils/constUrl";
const CheckboxAnswer = ({ question, language }) => {
  const languageStyles = () => {
    switch (language) {
      case "1":
        return {
          formType: "ltr",
          inputlabel: "choice_en",
          question: "question_en",
          choice: "choice_en",
        };
      case "2":
        return {
          formType: "rtl",
          inputlabel: "choice_ar",
          question: "question_ar",
          choice: "choice_ar",
        };
      case "3":
        return {
          formType: "rtl",
          inputlabel: "choice_ur",
          question: "question_ur",
          choice: "choice_ur",
        };
      case "4":
        return {
          formType: "ltr",
          inputlabel: "choice_ta",
          question: "question_ta",
          choice: "choice_ta",
        };
      case "5":
        return {
          formType: "ltr",
          inputlabel: "choice_hi",
          question: "question_hi",
          choice: "choice_hi",
        };
      default:
        return {
          formType: "ltr",
          inputlabel: "choice_en",
          question: "question_en",
          choice: "choice_en",
        };
    }
  };
  const resultStatus = (val) => {
    if (question.user_select_choice.length > 0) {
      if (question.user_select_choice.includes(val)) {
        return question.correct_answer.includes(val)
          ? "active-success"
          : "active-danger";
      }
      else {
        return "";
      }
    } else {
      return "";
    }
  };
  return (
    <div className="col-lg-6" dir={languageStyles().formType}>
      <div className="exam-result-box">
        <div className="d-flex">
          <div className="question-box d-flex align-items-center mx-1">
            <p>{question[languageStyles().question]}</p>
          </div>
          <div className="mark-box d-flex align-items-center justify-content-center">
            <div>
              <h3>{question.user_total_mark}</h3>
              <p>Mark</p>
            </div>
          </div>
        </div>
        <div className="result-section">
          {question.multiple_choice.map((itm, i) => {
            return (
              <div className="row" key={i}>
                <div className="col-lg-11">
                  {itm.choice !== "" && (
                    <div
                      className={`answer-bg d-flex align-items-center ${resultStatus(
                        itm.id
                      )}`}
                    >
                      <p>{itm[languageStyles().choice]}</p>
                    </div>
                  )}

                  {itm.Imagefield !== "" && itm.Imagefield !== null && (
                    <div
                      className={`answer-bg d-flex align-items-center ${resultStatus(
                        itm.id
                      )}`}
                    >
                      <div className={`answer-bg ${resultStatus(itm.id)}`}>
                        <img
                          src={MEDIA_URL + itm.Imagefield}
                          alt=""
                          className="img-fluid"
                        />
                      </div>
                    </div>
                  )}
                </div>
                <div className="col-lg-1">
                  <p className="count-text">{i + 1}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
export default CheckboxAnswer;
