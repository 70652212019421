import React from "react";
import { MEDIA_URL } from "../../utils/constUrl";
const MatrixCheckAnswer = ({ question, language }) => {
  const languageStyles = () => {
    switch (language) {
      case "1":
        return {
          formType: "ltr",
          inputlabel: "choice_en",
          question: "question_en",
          choice: "choice_en",
        };
      case "2":
        return {
          formType: "rtl",
          inputlabel: "choice_ar",
          question: "question_ar",
          choice: "choice_ar",
        };
      case "3":
        return {
          formType: "rtl",
          inputlabel: "choice_ur",
          question: "question_ur",
          choice: "choice_ur",
        };
      case "4":
        return {
          formType: "ltr",
          inputlabel: "choice_ta",
          question: "question_ta",
          choice: "choice_ta",
        };
      case "5":
        return {
          formType: "ltr",
          inputlabel: "choice_hi",
          question: "question_hi",
          choice: "choice_hi",
        };
      default:
        return {
          formType: "ltr",
          inputlabel: "choice_en",
          question: "question_en",
          choice: "choice_en",
        };
    }
  };
  const isChecked = (qid, ansid) => {
    if (!question.user_attend_matrix) {
      return false;
    } else {
      let countAns = question.user_attend_matrix.filter(
        (itm) =>
          itm.matrix_question_id === qid && itm.matrix_answer_id.includes(ansid)
      );
      return countAns.length > 0;
    }
  };
  return (
    <div className="col-md-12 mt-5 mb-3" dir={languageStyles().formType}>
      <h3 style={{ margin: "20px 0px 10px" }}>
        {question[languageStyles().question]}
      </h3>
      <table className="table-bordered w-100 matrix-tab">
        <thead>
          <tr>
            <th></th>
            {question.matrix_answer.map((itm, i) => {
              return <th key={i}>{itm[languageStyles().choice]}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {question.matrix_row.map((qitm, i) => {
            return (
              <tr key={i}>
                <th>{qitm[languageStyles().choice]}</th>
                {question.matrix_answer.map((itm, i) => {
                  return (
                    <td key={i}>
                      <div className="d-flex">
                        <h6>
                          {isChecked(qitm.id, itm.id) && (
                            <i className="fa fa-check" aria-hidden="true"></i>
                          )}
                        </h6>
                        {/* <label className="container_radio version_2">
                          <input
                            type="radio"
                            name="question_41"
                            value="No"
                            className="required"
                          />
                          <span className="checkmark"></span>
                        </label> */}
                      </div>
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
export default MatrixCheckAnswer;
