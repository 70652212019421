import React from "react";
import {
  CheckboxAnswer,
  DateTimeAnswer,
  RadioAnswer,
  TextAnswer,
  MatrixCheckAnswer,
  MatrixAnswer,
} from "../";
export default function FinalAnswer({ data }) {
  const dynamicWidget = (val, i) => {
    switch (val.Question_type) {
      case "Radio":
        return (
          <RadioAnswer
            question={val}
            key={i}
            language={data[0].select_lan_data}
          />
        );
      case "Checkbox":
        return (
          <CheckboxAnswer
            question={val}
            key={i}
            language={data[0].select_lan_data}
          />
        );
      case "multiple_line":
        return (
          <TextAnswer
            question={val}
            language={data[0].select_lan_data}
            key={i}
          />
        );
      case "text":
        return (
          <TextAnswer
            question={val}
            language={data[0].select_lan_data}
            key={i}
          />
        );
      case "num_text":
        return (
          <TextAnswer
            question={val}
            language={data[0].select_lan_data}
            key={i}
          />
        );
      case "Date_type":
        return (
          <DateTimeAnswer
            question={val}
            language={data[0].select_lan_data}
            key={i}
            isDate={true}
          />
        );
      case "Datetime_type":
        return (
          <DateTimeAnswer
            question={val}
            language={data[0].select_lan_data}
            key={i}
          />
        );
      case "Matrix_type":
        if (val.matrix_type === "one_choice") {
          return (
            <MatrixAnswer
              question={val}
              language={data[0].select_lan_data}
              key={i}
            />
          );
        } else {
          return (
            <MatrixCheckAnswer
              question={val}
              language={data[0].select_lan_data}
              key={i}
            />
          );
        }
    }
  };
  const loadWidgets = () => {
    return data.map((itm) => {
      return itm.question_bank.map((qitm, qi) => {
        return qitm.question_details.map((qans, qai) =>
          dynamicWidget(qans, qai)
        );
      });
    });
  };
  return (
    <div className="container">
      <h3 className="heading-of-exam">Exam Answers</h3>
      <div className="row">{loadWidgets()}</div>
    </div>
  );
}
