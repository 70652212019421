import React, { useState, useImperativeHandle, useRef } from "react";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import {
  changeMatrixRadioError,
  changeMatrixCheckError,
} from "../../redux/matrix";
import { changeAnswer } from "../../redux/survey";
import {
  CheckboxWidget,
  TextWidget,
  RadioWidget,
  //   RadioImageWidget,
  // CircularProgress,
  MatrixCheckWidget,
  MatrixWidget,
  DateTimeWidget,
  DateWidget,
  NumberWidget,
  TextareaWidget,
} from "../";
import { Link } from "react-router-dom";
import { BACKTO_DASHBOARD_URL } from "../../utils/constUrl";
export default function SectionNestedForm({
  results,
  seconds,
  minutes,
  hours,
  questionTimer,
  data,
  reference,
  testMode,
}) {
  const dispatch = useDispatch();
  const { matrixList, matrixCheckList } = useSelector((state) => state.matrix);
  const { surveyList } = useSelector((state) => state.survey);
  const [matrixRadio, setMatrixRadio] = useState([]);
  const [matrixCheck, setMatrixCheck] = useState([]);
  const [current, setCurrent] = useState(0);
  const [maxSection, setMaxSection] = useState(0);
  const formBtnRef = useRef();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
  } = useForm({
    shouldUseNativeValidation: false,
  });
  React.useEffect(() => {
    setMaxSection(data !== null ? data.pool.length : 0);
  });
  React.useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name !== undefined) {
        let nameSplit = name.split("-");
        // console.log(name, "===", nameSplit[1], nameSplit[2], nameSplit[3]);
        inputAnswer(
          value[name],
          nameSplit[1],
          nameSplit[2],
          nameSplit[3],
          nameSplit[4]
        );
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);
  // const { errors, isSubmitting } = formState;
  useImperativeHandle(reference, () => ({
    submitForm() {
      handleSubmit(autoSave)();
    },
  }));
  const inputAnswer = async (ans, sqmId, sectionId, pk, qType) => {
    if (qType === "Checkbox") {
      let cItem = surveyList.filter(
        (itm) =>
          itm.qid === Number(pk) &&
          itm.sqmId === Number(sqmId) &&
          itm.sId === Number(sectionId)
      );
      if (cItem.length > 0) {
        if (
          cItem[0].ans === null ||
          cItem[0].ans === "" ||
          cItem[0].ans.length === 0
        ) {
          dispatch(
            changeAnswer({
              sqmId: sqmId,
              sectionId: sectionId === null ? 0 : sectionId,
              qid: pk,
              ans: ans,
              checkBox: true,
            })
          );
        } else {
          dispatch(
            changeAnswer({
              sqmId: sqmId,
              sectionId: sectionId === null ? 0 : sectionId,
              qid: pk,
              ans: ans,
              checkBox: true,
            })
          );
        }
      } else {
        await dispatch(
          changeAnswer({
            sqmId: sqmId,
            sectionId: sectionId,
            qid: pk,
            ans: ans,
            checkBox: true,
          })
        );
      }
    } else {
      await dispatch(
        changeAnswer({
          sqmId: sqmId,
          sectionId: sectionId,
          qid: pk,
          ans: ans,
          checkBox: false,
        })
      );
    }
  };
  const autoSave = () => {
    // e.preventDefault();
    if (matrixCheckValidation() && matrixRadioValidation()) {
      results(answerFinder(), true, reset);
    } else {
      console.log("validation error");
    }
  };
  const save = (formRes, e) => {
    e.preventDefault();
    if (matrixCheckValidation() && matrixRadioValidation()) {
      if (questionTimer) {
        if (hours > 0 || minutes > 0 || seconds > 0) {
          if (current + 1 === maxSection) {
            results(answerFinder(), true, reset);
          }
          // else {
          //   setCurrent(current + 1);
          // }
        } else {
          results(answerFinder(), true, reset);
        }
      } else {
        if (current + 1 === maxSection) {
          results(answerFinder(), true, reset);
        }
        // else {
        //   console.log(current + 1, "current+1");
        //   setCurrent(current + 1);
        // }
      }
      // setCurrent(current + 1);
    } else {
      console.log("validation error");
    }
  };
  const answerFinder = () => {
    let ans = surveyList.map((itm) => {
      return {
        s_q_m_id: Number(itm.sqmId),
        qid: Number(itm.qid),
        sid: Number(itm.sId),
        ans: Array.isArray(itm.ans)
          ? itm.ans.length > 0
            ? itm.ans
            : []
          : itm.ans === null
          ? []
          : [itm.ans],
        matrix: "",
      };
    });
    let mat = [...matrixCheckList, ...matrixList].map((itm) => {
      return {
        s_q_m_id: itm.sqmId,
        qid: itm.qid,
        sid: itm.sId,
        ans: "",
        matrix: itm.data.map((mItm) => {
          return {
            r_id: mItm.rowId,
            ans_id: Array.isArray(mItm.ans)
              ? mItm.ans.length > 0
                ? mItm.ans
                : []
              : mItm.ans === null
              ? []
              : [mItm.ans],
          };
        }),
      };
    });
    return [...ans.filter((itm) => itm !== null && itm !== undefined), ...mat];
  };
  const matrixCheckValidation = () => {
    if (matrixCheck.length > 0) {
      let valTrue = 0;
      matrixCheck.map((itm) => {
        matrixCheckList.map(async (mq) => {
          if (mq.qid == itm) {
            let nullAnswers = mq.data.filter(
              (ansArr) => ansArr.ans == null || ansArr.ans.length === 0
            );
            if (nullAnswers.length > 0) {
              valTrue = valTrue + 1;
              await dispatch(
                changeMatrixCheckError({
                  qid: mq.qid,
                  rid: nullAnswers.map((itms) => {
                    return itms.rowId;
                  }),
                })
              );
            }
          }
        });
      });
      return valTrue > 0 ? false : true;
    } else {
      return true;
    }
  };
  const matrixRadioValidation = () => {
    if (matrixRadio.length > 0) {
      let valTrue = 0;
      matrixRadio.map((itm) => {
        matrixList.map(async (mq) => {
          if (mq.qid == itm) {
            let nullAnswers = mq.data.filter((ansArr) => ansArr.ans == null);
            if (nullAnswers.length > 0) {
              valTrue = valTrue + 1;
              await dispatch(
                changeMatrixRadioError({
                  qid: mq.qid,
                  rid: nullAnswers.map((itms) => {
                    return itms.rowId;
                  }),
                })
              );
            }
            // console.log(nullAnswers);
          }
        });
      });
      return valTrue > 0 ? false : true;
    } else {
      return true;
    }
  };
  const singleSaveAndNext = async () => {
    nextQuestion();
    if (matrixCheckValidation() && matrixRadioValidation()) {
      await results(answerFinder(), false);
    } else {
      console.log("validation error");
    }
  };
  const nextForm = async () => {
    // nextQuestion();
    let currentPool = data.pool[current];
    let currentPoolQuestion = currentPool.question_details;
    let manadatoryItems = currentPoolQuestion.filter(
      (itm) => itm.question_details[0].manadatory === true
    );
    // console.log(manadatoryItems);
    if (manadatoryItems.length > 0) {
      let notValidCount = 0;
      manadatoryItems.map(async (maItm) => {
        let vQid = maItm.question_details[0].pk;
        let vSqmId = maItm.pk;
        let vSid = maItm.Section_id_id;
        if (maItm.question_details[0].Question_type === "Matrix_type") {
          if (maItm.question_details[0].matrix_type === "one_choice") {
            let cMitem = matrixList.filter(
              (itm) =>
                itm.qid === vQid && itm.sqmId === vSqmId && itm.sId === vSid
            );
            let nullAnswers = cMitem[0].data.filter(
              (ansArr) => ansArr.ans == null
            );
            if (nullAnswers.length > 0) {
              notValidCount = notValidCount + 1;
              await dispatch(
                changeMatrixRadioError({
                  qid: vQid,
                  rid: nullAnswers.map((itms) => {
                    return itms.rowId;
                  }),
                })
              );
            }
          } else {
            let cMCitem = matrixCheckList.filter(
              (itm) =>
                itm.qid === vQid && itm.sqmId === vSqmId && itm.sId === vSid
            );
            let nullAnswers = cMCitem[0].data.filter(
              (ansArr) => ansArr.ans == null
            );
            if (nullAnswers.length > 0) {
              notValidCount = notValidCount + 1;
              await dispatch(
                changeMatrixCheckError({
                  qid: vQid,
                  rid: nullAnswers.map((itms) => {
                    return itms.rowId;
                  }),
                })
              );
            }
          }
        } else {
          let cItem = surveyList.filter(
            (itm) =>
              itm.qid === vQid && itm.sqmId === vSqmId && itm.sId === vSid
          );
          if (cItem.length > 0) {
            if (Array.isArray(cItem[0].ans)) {
              if (!cItem[0].ans.length > 0) {
                notValidCount = notValidCount + 1;
              }
            } else {
              if (cItem[0].ans === null || cItem[0].ans === "") {
                notValidCount = notValidCount + 1;
              }
            }
          }
        }
      });
      if (notValidCount <= 0) {
        singleSaveAndNext();
      }
    } else {
      singleSaveAndNext();
    }
  };
  const nextQuestion = () => {
    setCurrent(current + 1);
  };
  const lengthValidation = (question) => {
    if (question.validate_entry) {
      return {
        minLength: question.min_validate_entry,
        maxLength: question.max_validate_entry,
      };
    }
  };
  const dynamicWidget = (question, sectionId, sqmId, qbI) => {
    // console.log(question, sectionId, sqmId);
    switch (question.Question_type) {
      case "Radio":
        return (
          <RadioWidget
            key={qbI}
            sequence={data.Sequence_number ? qbI + 1 : ""}
            question={question}
            language={data.language_id}
            sqmId={sqmId}
            sectionId={sectionId}
            name={`input-${sqmId}-${sectionId}-${question.pk}-${question.Question_type}`}
            {...register(
              `input-${sqmId}-${sectionId}-${question.pk}-${question.Question_type}`,
              {
                required: {
                  value: question.manadatory,
                  message: "Please enter your input",
                },
              }
            )}
            inputError={
              Object.keys(errors).length === 0
                ? null
                : errors[
                    `input-${sqmId}-${sectionId}-${question.pk}-${question.Question_type}`
                  ]
            }
          />
        );

      case "Checkbox":
        return (
          <CheckboxWidget
            isHorizontal={data.choice_type == 1 ? true : false}
            key={qbI}
            sequence={data.Sequence_number ? qbI + 1 : ""}
            question={question}
            language={data.language_id}
            sqmId={sqmId}
            sectionId={sectionId}
            name={`input-${sqmId}-${sectionId}-${question.pk}-${question.Question_type}`}
            {...register(
              `input-${sqmId}-${sectionId}-${question.pk}-${question.Question_type}`,
              {
                required: {
                  value: question.manadatory,
                  message: "Please enter your input",
                },
              }
            )}
            inputError={
              Object.keys(errors).length === 0
                ? null
                : errors[
                    `input-${sqmId}-${sectionId}-${question.pk}-${question.Question_type}`
                  ]
            }
          />
        );
      case "multiple_line":
        return (
          <TextareaWidget
            key={qbI}
            sequence={data.Sequence_number ? qbI + 1 : ""}
            language={data.language_id}
            question={question}
            sqmId={sqmId}
            sectionId={sectionId}
            name={`input-${sqmId}-${sectionId}-${question.pk}-${question.Question_type}`}
            {...register(
              `input-${sqmId}-${sectionId}-${question.pk}-${question.Question_type}`,
              {
                required: {
                  value: question.manadatory,
                  message: "Please enter your input",
                },
                validate: (value, formValues) =>
                  question.manadatory ? value.trim() !== "" : true,
              }
            )}
            inputError={
              Object.keys(errors).length === 0
                ? null
                : errors[
                    `input-${sqmId}-${sectionId}-${question.pk}-${question.Question_type}`
                  ]
            }
          />
        );
      case "text":
        //validate_entry min_validate_entry max_validate_entry
        // minLength: 6, maxLength: 12
        if (question.input_email) {
          return (
            <TextWidget
              key={qbI}
              sequence={data.Sequence_number ? qbI + 1 : ""}
              language={data.language_id}
              question={question}
              sqmId={sqmId}
              sectionId={sectionId}
              name={`input-${sqmId}-${sectionId}-${question.pk}-${question.Question_type}`}
              {...lengthValidation(question)}
              {...register(
                `input-${sqmId}-${sectionId}-${question.pk}-${question.Question_type}`,
                {
                  required: {
                    value: question.manadatory,
                    message: "Please enter your email",
                    pattern:
                      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  },
                }
              )}
              inputError={
                Object.keys(errors).length === 0
                  ? null
                  : errors[
                      `input-${sqmId}-${sectionId}-${question.pk}-${question.Question_type}`
                    ]
              }
            />
          );
        } else {
          return (
            <TextWidget
              key={qbI}
              sequence={data.Sequence_number ? qbI + 1 : ""}
              language={data.language_id}
              question={question}
              sqmId={sqmId}
              sectionId={sectionId}
              name={`input-${sqmId}-${sectionId}-${question.pk}-${question.Question_type}`}
              {...lengthValidation(question)}
              {...register(
                `input-${sqmId}-${sectionId}-${question.pk}-${question.Question_type}`,
                {
                  required: {
                    value: question.manadatory,
                    message: "Please enter your input",
                  },
                  validate: (value, formValues) =>
                    question.manadatory ? value.trim() !== "" : true,
                }
              )}
              inputError={
                Object.keys(errors).length === 0
                  ? null
                  : errors[
                      `input-${sqmId}-${sectionId}-${question.pk}-${question.Question_type}`
                    ]
              }
            />
          );
        }
      case "num_text":
        return (
          <NumberWidget
            key={qbI}
            sequence={data.Sequence_number ? qbI + 1 : ""}
            language={data.language_id}
            question={question}
            sqmId={sqmId}
            sectionId={sectionId}
            name={`input-${sqmId}-${sectionId}-${question.pk}-${question.Question_type}`}
            {...register(
              `input-${sqmId}-${sectionId}-${question.pk}-${question.Question_type}`,
              {
                required: {
                  value: question.manadatory,
                  message: "Please enter your input",
                },
              }
            )}
            inputError={
              Object.keys(errors).length === 0
                ? null
                : errors[
                    `input-${sqmId}-${sectionId}-${question.pk}-${question.Question_type}`
                  ]
            }
          />
        );
      case "Date_type":
        return (
          <DateWidget
            key={qbI}
            sequence={data.Sequence_number ? qbI + 1 : ""}
            language={data.language_id}
            question={question}
            sqmId={sqmId}
            sectionId={sectionId}
            name={`input-${sqmId}-${sectionId}-${question.pk}-${question.Question_type}`}
            {...register(
              `input-${sqmId}-${sectionId}-${question.pk}-${question.Question_type}`,
              {
                required: {
                  value: question.manadatory,
                  message: "Please enter your input",
                },
              }
            )}
            inputError={
              Object.keys(errors).length === 0
                ? null
                : errors[
                    `input-${sqmId}-${sectionId}-${question.pk}-${question.Question_type}`
                  ]
            }
          />
        );
      case "Datetime_type":
        return (
          <DateTimeWidget
            key={qbI}
            sequence={data.Sequence_number ? qbI + 1 : ""}
            language={data.language_id}
            question={question}
            sqmId={sqmId}
            sectionId={sectionId}
            name={`input-${sqmId}-${sectionId}-${question.pk}-${question.Question_type}`}
            {...register(
              `input-${sqmId}-${sectionId}-${question.pk}-${question.Question_type}`,
              {
                required: {
                  value: question.manadatory,
                  message: "Please enter your input",
                },
              }
            )}
            inputError={
              Object.keys(errors).length === 0
                ? null
                : errors[
                    `input-${sqmId}-${sectionId}-${question.pk}-${question.Question_type}`
                  ]
            }
          />
        );
      case "Matrix_type":
        if (question.matrix_type === "one_choice") {
          return (
            <MatrixWidget
              key={qbI}
              sequence={data.Sequence_number ? qbI + 1 : ""}
              language={data.language_id}
              question={question}
              setMatrixRadio={setMatrixRadio}
              sectionId={sectionId}
              sqmId={sqmId}
            />
          );
        } else {
          return (
            <MatrixCheckWidget
              key={qbI}
              sequence={data.Sequence_number ? qbI + 1 : ""}
              language={data.language_id}
              question={question}
              setMatrixCheck={setMatrixCheck}
              sectionId={sectionId}
              sqmId={sqmId}
            />
          );
        }
    }
  };

  const languageStyles = () => {
    switch (data.language_id) {
      case "1":
        return {
          formType: "ltr",
          inputlabel: "choice_en",
          question: "question_en",
          choice: "choice_en",
        };
      case "2":
        return {
          formType: "rtl",
          inputlabel: "choice_ar",
          question: "question_ar",
          choice: "choice_ar",
        };
      case "3":
        return {
          formType: "rtl",
          inputlabel: "choice_ur",
          question: "question_ur",
          choice: "choice_ur",
        };
      case "4":
        return {
          formType: "ltr",
          inputlabel: "choice_ta",
          question: "question_ta",
          choice: "choice_ta",
        };
      case "5":
        return {
          formType: "ltr",
          inputlabel: "choice_hi",
          question: "question_hi",
          choice: "choice_hi",
        };
      default:
        return {
          formType: "ltr",
          inputlabel: "choice_en",
          question: "question_en",
          choice: "choice_en",
        };
    }
  };
  const questionBlock = (poolItm, poolId) => {
    return (
      poolId === current && (
        <div key={poolId}>
          {poolItm.question_details.map((qbItm, qbI) => {
            return dynamicWidget(
              qbItm.question_details[0],
              qbItm.Section_id_id,
              qbItm.pk,
              qbI
            );
          })}
        </div>
      )
    );
  };
  return (
    <div className="container-fluid px-0">
      <div id="form_container">
        <div className="row no-gutters">
          <div className="col-lg-12">
            <div className="top-bg d-flex align-items-center justify-content-between flex-md-row flex-column">
              <div className="exam-heading d-flex align-items-center">
                {/* <i
                  className="fa-solid fa-angle-left backicn"
                  //   onClick={() => navigate(-1)}
                ></i> */}
                <div>
                  <h2>{data.exam_title}</h2>
                  {data.information_list.map((itm, i) => (
                    <h6>
                      {itm.title}: {itm.answer}
                    </h6>
                  ))}
                  {/* <h6>Maximum Score: {data.exam_total_mark}</h6> */}
                  {/* <h6>Total Questions: {data.total_questions_count}</h6> */}
                </div>
              </div>

              <div className="total-tym">
                {testMode && (
                  <Link to={BACKTO_DASHBOARD_URL}>
                    <button type="button" className="dashboard-class ml-2">
                      Back to Dashboard
                    </button>
                  </Link>
                )}
                {questionTimer && (
                  <h6 className="d-flex align-items-end">
                    Total Time Left:
                    <div id="countdown">
                      {hours > 0 ? hours : 0}:{minutes > 0 ? minutes : 0}:
                      {seconds > 0 ? seconds : 0}
                    </div>
                  </h6>
                )}
              </div>
            </div>

            {/* <CircularProgress time="10s" /> */}
            <div dir={languageStyles().formType}>
              <form onSubmit={handleSubmit(save)}>
                <div className="m-5">
                  <div id="middle-wizard">
                    {data != null &&
                      data.pool[current].section_title !== null && (
                        <div className="section-heading">
                          <h2>{data.pool[current].section_title}</h2>
                        </div>
                      )}
                    {data != null &&
                      data.pool.map((poolItm, poolId) => {
                        return questionBlock(poolItm, poolId);
                      })}
                    {/* {data != null &&
                      data.pool[current].question_details.map((qItm, qKey) => {
                        return dynamicWidget(
                          qItm.question_details[qKey],
                          qItm.Section_id_id,
                          qItm.pk
                        );
                      })} */}
                  </div>
                  <div id="bottom-wizard">
                    {current !== 0 && (
                      <button
                        type="button"
                        name="backward"
                        className="backward mb-1"
                        onClick={() => setCurrent(current - 1)}
                      >
                        Prev
                      </button>
                    )}
                    {maxSection !== current + 1 && (
                      <button
                        type="submit"
                        name="forward"
                        className="forward mx-1 mb-1"
                        // ref={formBtnRef}
                        onClick={nextForm}
                      >
                        Next
                      </button>
                    )}
                    {maxSection === current + 1 && (
                      <button
                        type="submit"
                        name="process"
                        className="submit mx-1 submit-cbtn"
                        ref={formBtnRef}
                      >
                        Submit
                      </button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div id="top-wizard">
          <div
            id="progressbar"
            style={{
              padding: current === 0 ? 0 : 4,
              backgroundColor: "#351A5C",
              width: `${((current / maxSection) * 100).toFixed()}%`,
            }}
          ></div>
          {data.progress === "Percentage" && (
            <span id="location">
              {`${((current / maxSection) * 100).toFixed()}%`} completed
            </span>
          )}
          {data.progress === "Number" && (
            <span id="location">
              {current + 1} of {maxSection} | {current} completed
            </span>
          )}
        </div>
      </div>
    </div>
  );
}
