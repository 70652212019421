import React from 'react'

export default function CircularProgress({timer,actualTime}) {
    return (
        <div className="timer">
            <div className="timer-overlay"></div>
            <div id="countdown-number">{timer} </div>
            <svg viewBox="0 0 100 100">
                <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
                    <stop offset="10%" stopColor="#C096EA" />
                    <stop offset="100%" stopColor="#7012CE" />
                </linearGradient>
                <circle
                    className="main-circle"
                    cx={50}
                    cy={50}
                    r={45}
                    stroke="url(#gradient)"
                    strokeWidth={10}
                    fill="none"
                    transform="rotate(30 50 50)"
                    style={{ animationDuration: actualTime }}
                // style={{animation: "countdown 20s linear infinite forwards"}}
                ></circle>
            </svg>
        </div>
    )
}
