import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TextBox } from "../";
import { changeAnswer, addToSurveyList } from "../../redux/survey";
import { MEDIA_URL } from "../../utils/constUrl";
const TextWidget = React.forwardRef(
  (
    { inputError, question, language, sectionId, resteField, sqmId,sequence, ...rest },
    ref
  ) => {
    // console.log(inputError,"inputError");
    const dispatch = useDispatch();
    const { surveyList } = useSelector((state) => state.survey);
    useEffect(() => {
      // resteField();
      initialState();
    }, []);
    const initialState = async () => {
      await dispatch(
        addToSurveyList({
          sqmId,
          sId: sectionId===null ?0:sectionId,
          qid: question.pk,
          ans: null,
          required: question.manadatory,
        })
      );
    };
    const inputAnswer = (ans) => {
      dispatch(
        changeAnswer({
          sqmId: sqmId,
          sectionId: sectionId===null ?0:sectionId,
          qid: question.pk,
          ans: ans,
        })
      );
    };
    const findValue = () => {
      let cItem = surveyList.filter(
        (itm) =>
          itm.qid === question.pk &&
          itm.sqmId === sqmId &&
          itm.sId === sectionId
      );
      if (cItem.length > 0) {
        return cItem[0].ans === null ? "" : cItem[0].ans;
      } else {
        return "";
      }
    };
    const languageStyles = () => {
      switch (language) {
        case "1":
          return {
            formType: "ltr",
            inputlabel: "choice_en",
            question: "question_en",
            choice: "choice_en",
          };
        case "2":
          return {
            formType: "rtl",
            inputlabel: "choice_ar",
            question: "question_ar",
            choice: "choice_ar",
          };
        case "3":
          return {
            formType: "rtl",
            inputlabel: "choice_ur",
            question: "question_ur",
            choice: "choice_ur",
          };
        case "4":
          return {
            formType: "ltr",
            inputlabel: "choice_ta",
            question: "question_ta",
            choice: "choice_ta",
          };
        case "5":
          return {
            formType: "ltr",
            inputlabel: "choice_hi",
            question: "question_hi",
            choice: "choice_hi",
          };
        default:
          return {
            formType: "ltr",
            inputlabel: "choice_en",
            question: "question_en",
            choice: "choice_en",
          };
      }
    };
    return (
      <div className="step">
        <h3 className="main_question">
          <i className="arrow_right"></i>
          {sequence&&sequence+". "}{question[languageStyles().question]}
        </h3>
        {question.Description !== "" && <small>{question.Description}</small>}
        {question.comments !== "" && <small>{question.comments}</small>}
        {question.multiple_image.length > 0 && (
          <div className="d-flex justify-content-center">
            {question.multiple_image.map((imgItm, im) => {
              return (
                <div className="qimg-box" key={im}>
                  <img
                    src={MEDIA_URL + imgItm.file_data}
                    className="img-fluid img-center"
                  />
                </div>
              );
            })}
          </div>
        )}
        <div className="row d-flex justify-content-center">
          <div className="col-lg-8">
            <div className="form-group">
              {inputError !== null && inputError !== undefined && (
                <span htmlFor="question_2" className="error">
                  Required
                </span>
              )}
              <TextBox
                className="text-box"
                placeholder={
                  question.Placeholder_msg === ""
                    ? question[languageStyles().question]
                    : question.Placeholder_msg
                }
                defaultValue={findValue()}
                // onChange={(e) => {
                //   inputAnswer(e.target.value);
                // }}
                {...rest}
                ref={ref}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
);
export default TextWidget;
